export const environment = {
  production: false,
  identityPoolId: 'us-west-2:ba6f576e-f752-4fd4-b0c9-4b1c7257782f',
  userPoolId: 'us-west-2_cDLV127Qh',
  webClientId: '1uao7ea047iq8sonkvmmbvbn4v',
  bucket: 'noe-dev',
  api: 'https://ex7nsp2x87.execute-api.us-west-2.amazonaws.com/dev/noe',
  apiCore: 'https://ex7nsp2x87.execute-api.us-west-2.amazonaws.com/dev/noe/core',
  xApiKey: 'fUSq6Xi1Zj2xVG9aLGL6q9KRmzRqM0mo4OaYXHWi',
  captchaSiteKey:'6LfBENkaAAAAAP9qn7Z73BYMsrdvE-bfcZ92i7VX',
  reporteReplace: 'https://noe-dev.s3.amazonaws.com/',
  TESTING : false,
  rutaRegionQa:'https://noe-dev.s3.us-west-2.amazonaws.com',
  socketEndpoint:'wss://f0q7znsrq6.execute-api.us-west-2.amazonaws.com/noedev'
};
