<div class="top-bar">

    <div class="company-dropdown">
        <ng-container *ngIf="!esGratuita">
            <!--<div class="profile">
                <span class="site-logo1__logo-container">
                    <img 
                        [src]="logoSidebar" style="width: 100px"
                        alt="NOE - Notificaciones Electrónicas"
                    >
                </span>-->
                <!--<button (click)="toggle($event)">
                    <i class="las la-chevron-left"></i>
                </button>-->
                <div class="selected" (click)="$event.stopPropagation(); closeDrop(); drop=!drop">
                    <b>Empresa:</b> {{empresaActiva.razon_social}}
                    <span class="arrow"></span>
                </div>
                
            <!--</div>-->

            <div class="dropdown" [class.open]="drop">
                <ul>
                    <li [class.disabled]="e.id == empresaActiva.id" *ngFor="let e of empresas" (click)="$event.stopPropagation(); seleccionarEmpresaActiva(e)">{{e.razon_social}}</li>
                </ul>
            </div>
        </ng-container>

        <a class="boton_personalizado" *ngIf="esGratuita" (click)="solicitarPremium()">Solicitar versión premium</a>

    </div>

    <!-- <select [(ngModel)]="empresaActiva" (change)="seleccionarEmpresaActiva($event)">
        <option *ngFor="let e of empresas" [value]="e.id">{{e.razon_social}}</option>
    </select> -->
    <!-- <app-autocomplete
        placeholder="Buscar:"
    ></app-autocomplete> -->
    <!-- <app-search></app-search> -->

    <div class="actions">
        <div class="profile">
            <!--<div id="google_translate_element"></div>-->
    
            <div *ngIf="this.authService.obtenerSubdominio() == 'portal-qa'" class="title">
                <h4 (click)="$event.stopPropagation(); closeProfileTraductor(); profileTraductor=!profileTraductor">Seleccionar Idioma <span class="arrow"></span></h4>
                <div class="dropdown" [class.open]="profileTraductor">
                    <ul>
                        <li>
                            <a href="#googtrans(es|es)" class="lang-select">Español<span><img class="img-bandera" src="../../assets/img/banderas/peru.png"></span></a>
                        </li>
                        <li>
                            <hr>
                            <a href="#googtrans(es|en)" class="lang-select">Inglés<span><img class="img-bandera" src="../../assets/img/banderas/estados-unidos.png"></span></a>
                        </li>
                        <li>
                            <hr>
                            <a href="#googtrans(es|fr)" class="lang-select">Francés<span><img class="img-bandera" src="../../assets/img/banderas/francia.png"></span></a>
                        </li>
                        <li>
                            <hr>
                            <a href="#googtrans(es|it)" class="lang-select">Italiano<span><img class="img-bandera" src="../../assets/img/banderas/italia.png"></span></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="title">
                <h4 (click)="$event.stopPropagation(); closeProfileDrop(); profileDrop=!profileDrop">{{name}} <span class="arrow"></span></h4>
                <!-- <p>{{profiles}}</p> -->

                <div class="dropdown" [class.open]="profileDrop">
                    <ul>
                        <li class="profile-list">
                            <span *ngFor="let p of profiles">{{p}}</span>
                        </li>
                        <li>
                            <hr>
                            <a (click)="initChangePass()">Cambiar Contraseña</a>
                        </li>
                        <li>
                            <hr>
                            <a (click)="onUpdateUser()">Modificar Mi Cuenta</a>
                        </li>
                        <li>
                            <hr>
                            <a>Manuales y Tutoriales</a>
                        </li>
                        <li>
                            <hr>
                            <a (click)="abrirModalLecturaTerminos()">Términos y Condiciones</a>
                        </li>
                    </ul>

                </div>
            </div>
            <button class="logout" (click)="abrirModalConfirmarSalir()"><i class="las la-power-off"></i> <span>Salir</span></button>

            <button class="toggler" (click)="toggle($event)">
                <i class="las la-bars"></i>
            </button>
        </div>

    </div>

</div>

<app-modal-lectura #modalLecturaTerminos titleModal="Términos y Condiciones" [scroller]="true">
    <app-terminos-y-condiciones></app-terminos-y-condiciones>
</app-modal-lectura>

<app-modal #passModal [hide-footer]="true" (onclose)="passForm.reset()" titleModal="Cambiar Contraseña">
    <h4>Fecha Actualizacion de Contraseña</h4>
    <div class="double-row">
        <p *ngIf="dataPassword"> {{dataPassword}}</p>
        <p *ngIf="dataPassword"><b>{{dataPasswordDate}} dias para vencer</b></p>
    </div>

    <form [formGroup]="passForm" (submit)="changePassword($event)" *ngIf="passForm">

        <app-input type="password" formControlName="oldPass" label="Contraseña Actual" [required]="true"></app-input>


        <app-input type="password" formControlName="password" label="Nueva Contraseña" [required]="true"></app-input>

        <app-input type="password" formControlName="confirm" label="Confirmar Contraseña" [required]="true"></app-input>

        <p class="error-message" *ngIf="passForm.errors?.mismatch">Las contraseñas deben coincidir</p>

        <app-password-validators [form]="passForm" [validators]="this.validators.validators">
        </app-password-validators>

        <div class="form-footer">
            <button *ngIf="!pLoading" class="btn" type="submit">Confirmar</button>

            <button *ngIf="pLoading" class="btn disabled loading" type="submit">
                Confirmando
            </button>
        </div>

    </form>

</app-modal>

<app-modal #editarMiCuentaModal [hide-footer]="true" titleModal="Editar Mi Cuenta">
    <form [formGroup]="usuarioForm" (submit)="handleSubmit($event)" *ngIf="usuarioForm">

        <app-input type="text" formControlName="name" label="Nombre" [required]="true"></app-input>

        <div class="double-row">
            <app-input type="text" label="Apellido Paterno" formControlName="paternalSurname" [required]="true"></app-input>

            <app-input type="text" label="Apellido Materno" formControlName="maternalSurname" [required]="true"></app-input>
        </div>

        <div class="double-row">
            <app-input type="select" label="Tipo de Documento" formControlName="identityType" [selectOptions]="tipoDocumentos" [required]="true"></app-input>

            <app-input type="text" label="Número de Documento" formControlName="identityNumber" [required]="true"></app-input>
        </div>

        <app-input type="email" label="Correo Electrónico" formControlName="email" [required]="true" [readonly]="true"></app-input>

        <div class="double-row">

            <app-input type="select" label="Código de país" formControlName="nationalType" [selectOptions]="countries" [selectOptionsName]="'optionName'" [selectOptionsKey]="'code'" [required]="true"></app-input>
            <app-input type="tel" label="Teléfono" formControlName="phoneNumber" [required]="true"></app-input>
        </div>


        <div class="form-footer">
            <button *ngIf="!pLoading" class="btn" type="submit" [class.loading]="crearLoading">Confirmar</button>
            <button *ngIf="!pLoading" class="btn danger" type="button" (click)="cerrarModalEditarCuenta()">Cancelar</button>

            <button *ngIf="pLoading" class="btn disabled loading" type="submit">
                Confirmando
            </button>
        </div>

    </form>

    <ng-template #loading>
        <app-loader [big]="true"></app-loader>
    </ng-template>

</app-modal>


<app-modal #modalCambiarmeCuentaPago titleModal="CAMBIARME A CUENTA PREMIUM" [hide-footer]="true">
    <app-table #table *ngIf="!flagSubdomainMasa" [iconsOpt]="true" [head]="headPremium" [body]="bodyPremium" [showPaginator]="false" [showIconSort]="false"></app-table>
    <app-table-sin-scroll #table *ngIf="flagSubdomainMasa" [iconsOpt]="true" [head]="headPremium" [body]="bodyPremium" [showPaginator]="false" [showIconSort]="false"></app-table-sin-scroll>
    <div class="form-footer">
        <button class="btn success" (click)="aceptarCambiarmeCuentaPago()" [class.loading]="modalCambiarmeCuentaPagoLoading">Quiero que me contacten</button>
    </div>

</app-modal>

<app-modal #modalConfirmarLogout titleModal="CONFIRMAR" (onconfirm)="confirmarSalir()">
    <p>¿Estas seguro de cerrar sesión?</p>
</app-modal>