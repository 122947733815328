import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


type requestBody = {
  method: string
  data?: any,
  id?: string,
  idProcess?: number
}


@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

  private urlRoles:string = `${environment.apiCore}/process_tracking/`;

  constructor( private http: HttpClient ) { }

  listarProcesos(sub:string, user:string, empresa:any, metadata:any):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS', data: {subdomain:sub, user:user, idCompany:empresa, metadata:metadata}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosPaginationTotal(sub:string, user:string, empresa:any, metadata:any, offset:number,row_count:number, filters:any):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-PAGINATION-TOTAL', data: {subdomain:sub, user:user, idCompany:empresa, metadata:metadata, offset:offset, row_count:row_count, filters:filters}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  /*listarProcesosAnulados(sub:string, user:string, empresa:any, metadata:any):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-CANCELL', data: {subdomain:sub, user:user, idCompany:empresa, metadata:metadata}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }*/

  listarProcesosAnulados(sub:string, user:string, empresa:any, metadata:any,offset:number,row_count:number, filters:any):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-CANCELL', data: {subdomain:sub, user:user, idCompany:empresa, metadata:metadata,offset:offset, row_count:row_count, filters:filters}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentos(user:string, id:any):Observable<any> {
    const body:requestBody = { method: 'LIST-VIEW-DOCUMENTS', data: { user:user, idSigningProcess:id } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  descargarZip(user:string, id:any, idCom:any):Observable<any> {
    const body:requestBody = { method: 'DOWNLOAD-ZIP', data: { user:user, idSigningProcess:id , idCompany:idCom} }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  descargarZipMasivo(user:string, listaId:any, idCom:any):Observable<any> {
    const body:requestBody = { method: 'DOWNLOAD-ZIP-MASSIVE', data: { user:user, listaId:listaId , idCompany:idCom} }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  verDocumento(path:string):Observable<any> {
    const body:requestBody = { method: 'SHOW-DOCUMENT', data: { filePath:path } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  descargarDocumento(id:any, path:string):Observable<any> {
    const body:requestBody = { method: 'SHOW-DOCUMENT', data: { id:id, filePath:path } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarTrazabilidad(id:any, subdomain:string, idCompany:any):Observable<any> {
    const body:requestBody = { method: 'LIST-USER-FLOW', data: { idSigningProcess:id, subdomain:subdomain, idCompany :idCompany } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDetalleFirma(user:string, id:any, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'LIST-DETAIL-FIRM', data: { userRecipient:user, idSigningProcess:id, subdomain:subdomain } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  reporteHistorial(user:string, id:number, subdomain:string):Observable<any> {
    const body:requestBody = { method: 'REPORT-HISTORY', data: { user:user, idSigningProcess:id, subdomain:subdomain } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarFechaLimiteFirma(id:string, fecha:any):Observable<any> {
    const body:requestBody = {
      method:"UPDATE-LIMIT-FIRM",
      data:{
        id:id,
        date:fecha
      }
    }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  cancelarProceso(idSigningProcess: number, cancellMotive: string, cancellUser: string, empresa:any) {
    const body: requestBody = { method: 'CANCEL-PROCESS', data: {cancellMotive:cancellMotive, cancellUser:cancellUser,idSigningProcess:idSigningProcess,idCompany:empresa  } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  eliminarProceso(idSigningProcess: number, cancellMotive: string, cancellUser: string, empresa:any) {
    const body: requestBody = { method: 'DELETE-PROCESS', data: {cancellMotive:cancellMotive, cancellUser:cancellUser,idSigningProcess:idSigningProcess,idCompany:empresa  } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarProcesosPagination(sub:string, user:string, empresa:any, offset:number,row_count:number, filters:any):Observable<any> {
    const body:requestBody = { method: 'LIST-PROCESS-PAGINATION-DEMO', data: {subdomain:sub, user:user, idCompany:empresa,offset:offset,row_count:row_count,filters:filters}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerTotalProcesosPagination(sub:string, user:string, empresa:any, offset:number,row_count:number, filters:any):Observable<any> {
    const body:requestBody = { method: 'TOTAL-PROCESS-PAGINATION-DEMO', data: {subdomain:sub, user:user, idCompany:empresa,offset:offset,row_count:row_count,filters:filters}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerTotalProcesos(sub:string, empresa:any,filters:any):Observable<any> {
    const body:requestBody = { method: 'GET-TOTAL-PROCESS', data: {subdomain:sub, idCompany:empresa,filters:filters}}
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

}
