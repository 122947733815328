import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Perfil } from '../models/Perfil.model';
import { Usuario } from '../models/Usuario.model';
import { IField } from '@noe/models/Field.model';

export interface IExpirationTime {
  timeLeft: number;
  idTimer: any;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private urlPreAuth:string = `${environment.api}/users/pre-authentication`;
  private urlRecover:string = `${environment.api}/users/pre-recover`;
  private urlReset: string = `${environment.apiCore}/company/`;
  private IDExpirationTimer: any;
  timeLeft$: Subject<IExpirationTime> = new Subject<IExpirationTime>();
  refreshToken$ = new BehaviorSubject<string>(null);
  private userLoggedIn = new Subject<any>();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.userLoggedIn.next({});
   }

  setUserLoggedIn(userLoggedIn: any) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<any> {
    return this.userLoggedIn.asObservable();
  }

  preAuth(user:string, sub:string,recapcha:string) {
    return this.http.post(this.urlPreAuth, {username:user,subdomain:sub,captchaResponse:recapcha}, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
  }

  preRecover(user:string, sub:string) {
    return this.http.post(this.urlRecover, {username:user,subdomain:sub}, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
  }

  restToken(): Observable<any> {
    const body = { method: 'RESET' };
    
    return this.http.post(this.urlReset, body, { 
      headers: { "x-api-key": environment.xApiKey } 
    });
  }

  guardarDatos(usuario:Usuario, reload:boolean = false):void {

    this.generarPermisos(usuario.profiles)

    this.guardarUsername(usuario.email)
    this.guardarNombres(usuario.name, usuario.paternalSurname, usuario.maternalSurname)
    this.guardarDocumento(usuario.identityNumber)
    this.guardarEmpresas(usuario.companys)
    this.guardarIdUserGroup(usuario.idUserGroup)
    this.guardarSubdominio(usuario.subdomain)
    if (!reload) this.guardarEmpresaActiva(usuario.companys[0])
    this.guardarRestricciones(usuario.attributes)
    this.guardarRoles(usuario.attributes)
    this.guardarRolesArreglo(usuario.attributes)
    this.guardarPerfiles(usuario.profiles)
    this.guardarFlagUsuarioNuevo(usuario.flagSession)
    this.guardarFlagTerminosyCondiciones(usuario.flagTerms)

    if (usuario.attributes.tipos_documentos) this.guardarTipoDocumentos(usuario.attributes.tipos_documentos)
    if (usuario.attributes.tipos_firmas) this.guardarTipoFirmas(usuario.attributes.tipos_firmas)
    if (usuario.attributes.documentos_visado) this.guardarDocumentosVisado(usuario.attributes.documentos_visado)
  }

  // Permisos
  generarPermisos(profiles:Perfil[]) {
    let permisos = []
    profiles.forEach((p:Perfil) => { p.options.permisos.forEach(o => {
      if (this.obtenerIdEmpresaActiva() == p.idCompany) {
        permisos.push(o)
      }
    }) })
    
    let unique = new Set(permisos)
    this.guardarOpciones(Array.from(unique))
  }

  // Key usuario
  guardarUsername(username:string):void {
    localStorage.setItem('USERNAME', username)
  }
  obtenerUsername():string {
    return localStorage.getItem('USERNAME')
  }

  obtenerFlagUsuarioNuevo():string {
    return localStorage.getItem('NEWUSER')
  }

  // Nombres
  guardarNombres(name:string, paternal:string, maternal:string):void {
    const nombres:string[] = [name,paternal,maternal]
    const nombreCadena : string = name+" "+paternal+" "+maternal
    localStorage.setItem('NOMBRESCADENA', JSON.stringify(nombreCadena))
    localStorage.setItem('NOMBRES', JSON.stringify(nombres))
  }
  obtenerNombres():string[] {
    return JSON.parse(localStorage.getItem('NOMBRES'))
  }
  obtenerNombresCadena():string {
    return JSON.parse(localStorage.getItem('NOMBRESCADENA'))
  }

  // Perfiles
  guardarPerfiles(data:any[]):void {
    const perfiles:string[] = data.filter(d => {
      if (d.idCompany == this.obtenerIdEmpresaActiva()){
        return true
      }
      return false
    }).map(p => p.nameProfile)
    localStorage.setItem('PROFILES', JSON.stringify(perfiles))
  }
  obtenerPerfiles() {
    return JSON.parse(localStorage.getItem('PROFILES'))
  }

  // Documento Identidad
  guardarDocumento(numero:string):void {
    localStorage.setItem('DOCUMENTO', numero)
  }

  guardarFlagUsuarioNuevo(numero:string):void {
    localStorage.setItem('NEWUSER', numero)
  }

  guardarFlagTerminosyCondiciones(numero:string):void {
    localStorage.setItem('TERMINOS_Y_CONDICIONES', numero)
  }

  obtenerFlagTerminosyCondiciones():number {
    return Number.parseInt(localStorage.getItem('TERMINOS_Y_CONDICIONES'))
  }

  obtenerDocumento():string {
    return localStorage.getItem('DOCUMENTO')
  }

  // Empresas
  guardarEmpresas(empresas:{id?:string,razon_social?:string,ruc?:string}[]):void {
    localStorage.setItem('EMPRESAS', JSON.stringify(empresas))
  }
  obtenerEmpresas():{id?:string,razon_social?:string,ruc?:string}[] {
    return JSON.parse(localStorage.getItem('EMPRESAS'))
  }

  // Data Empresa
  guardarDataEmpresaActiva(data:any):void {
    localStorage.setItem('DATA_EMPRESA', JSON.stringify(data))
  }
  obtenerAbreviaturaEmpresaActiva():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.abbrevation
  }

  obtenerActiveMetadataEmpresa():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.activeMetadata
  }

  getActiveMetadataProcess(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeMetadataProcess;
  }

  getActivateFlagVisadoConfiguredPositions(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateFlagVisadoConfiguredPositions;
  }

  getActivateFlagComentaries(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateFlagComentaries;
  }

  getActivateFlagConsultaGeneral(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateFlagConsultaGeneral;
  }

  getActivateExtraFieldsToTables(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateExtraFieldsToTables;
  }

  getactiveFlagPapelera(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagPapelera;
  }

  getActivateFlagReminder():number{
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateFlagReminder;
  }

  getActivateFlagStep2():number{
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateFlagStep2;
  }

  getActiveFlagFlowTypeDocument(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagFlowTypeDocument;
  }

  getActiveFlagVisorDocument(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.flagIgnoreVisorVolvo;
  }


  getActiveFlagSignatureForDocument(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagSignatureForDocument;
  }

  getActiveFlagModuleCargo(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagModuleCargo;
  }

  getflagSubdomainMasa(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.flagSubdomainMasa;
  }

  getStringReasonSocial(): string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.reasonSocial;
  }

  getactiveFlagSession(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagSession;
  }

  getActivateSharedInformationForCompanyGroup(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activateSharedInformationForCompanyGroup;
  }

  getActiveFlagOffices(): number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return empresa.activeFlagOffices;
  }

  obtenerTipoCuenta():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.cuenta
  }

  obtenerTipoCuentaPago():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.tipo_cuenta
  }

  obtenerLimiteUsuarios():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.limite_usuarios
  }

  obtenerTipoFirmasEmpresa():number[] {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.tipos_firma
  }

  getFields(): IField[] {
    const company = JSON.parse(localStorage.getItem('DATA_EMPRESA'));
    return company.attributes.fields;
  }

  obtenerDocumentosAsignados():number[] {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.documentos_asignado
  }

  obtenerLimitePeso():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.soporte_archivos
  }
  obtenerCantidadDocumentos():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.documentos_asignado
  }
  obtenerLimiteCarga():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.carga_carpetas
  }
  obtenerCantidadDocumentosPermitidos():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.license.cantidad_documentos
  }
  obtenerPermisoPersonalizacion():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.perzonalizacion
  }
  obtenerCantidadSelloTiempo():string {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.attributes.options.cantidad_sello_tiempo
  }
  obtenerTiempoExpiracion():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return parseInt(empresa.tokenExpiration)
  }
  obtenerIdGrupoEmpresarial():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return parseInt(empresa.groupCompanyId)
  }
  obtenerEstadoActivarTimbrado():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return parseInt(empresa.activateTimbrado)
  }
  obtenerActiveDescargaDocumentos():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return parseInt(empresa.activeDownloadDocuments)
  }
  

  obtenerActivateFlagAgrupationEmpresa():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.activateFlagAgrupation
  }

  obtenerActivateFlagSendMultiNotification():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.activateFlagSendMultiNotification
  }

  obtenerActivateExternalApiConsume():number {
    const empresa = JSON.parse(localStorage.getItem('DATA_EMPRESA'))
    return empresa.activateExternalApiConsume
  }

  // Grupo Usuario
  guardarIdUserGroup(grupo:string):void {
    localStorage.setItem('ID_USER_GROUP', grupo)
  }
  obtenerIdUserGroup():string {
    return localStorage.getItem('ID_USER_GROUP')
  }

  // Opciones Usuario
  guardarOpciones(opciones:string[]):void {
    localStorage.setItem('OPCIONES', JSON.stringify(opciones))
  }
  obtenerOpciones():string[] {
    return JSON.parse(localStorage.getItem('OPCIONES'))
  }

  // Subdominio
  guardarSubdominio(subdominio:string) {
    localStorage.setItem('SUBDOMINIO', subdominio)
  }
  obtenerSubdominio():string {
    return localStorage.getItem('SUBDOMINIO')
  }

  // Id Empresa Activa (Seleccionada)
  guardarIdEmpresaActiva(id:string):void {
    localStorage.setItem('ID_EMPRESA_ACTIVA', id)
  }
  obtenerIdEmpresaActiva():string {
    return localStorage.getItem('ID_EMPRESA_ACTIVA')
  }

  // Razon social Empresa Activa (Seleccionada)
  guardarRazonSocialEmpresaActiva(razonSocial:string):void {
    localStorage.setItem('RAZON_SOCIAL_EMPRESA_ACTIVA', razonSocial)
  }
  obtenerRazonSocialEmpresaActiva():string {
    return localStorage.getItem('RAZON_SOCIAL_EMPRESA_ACTIVA')
  }

  // Ruc Empresa Activa (Seleccionada)
  guardarRucEmpresaActiva(ruc:string):void {
    localStorage.setItem('RUC_EMPRESA_ACTIVA', ruc)
  }
  obtenerRucEmpresaActiva():string {
    return localStorage.getItem('RUC_EMPRESA_ACTIVA')
  }

  // Guardar Empresa Activa (Seleccionada)
  guardarEmpresaActiva(empresa:{id?:string,razon_social?:string,ruc?:string}):void {
    this.guardarIdEmpresaActiva(empresa.id)
    this.guardarRazonSocialEmpresaActiva(empresa.razon_social)
    this.guardarRucEmpresaActiva(empresa.ruc)
  }

  // Tipos documentos
  guardarTipoDocumentos(docs:number[]):void {
    localStorage.setItem('TIPO_DOCUMENTOS', JSON.stringify(docs))
  }
  obtenerTipoDocumentos():number[] {
    return JSON.parse(localStorage.getItem('TIPO_DOCUMENTOS'))
  }

  // Documentos visado
  guardarDocumentosVisado(docs:number[]) {
    localStorage.setItem('DOCUMENTOS_VISADO', JSON.stringify(docs))
  }
  obtenerDocumentosVisado():number[] {
    return JSON.parse(localStorage.getItem('DOCUMENTOS_VISADO'))
  }

  // Tipos de firmas
  guardarTipoFirmas(firmas:number[]):void {
    localStorage.setItem('TIPO_FIRMAS', JSON.stringify(firmas))
  }
  obtenerTipoFirmas():number[] {
    return JSON.parse(localStorage.getItem('TIPO_FIRMAS'))
  }

  // Restricciones Proceso
  guardarRestricciones(atributos):void {
    const config:any = {}
    if (atributos.desactivar_proceso_firma) 
    config.enableDisable = JSON.parse(atributos.desactivar_proceso_firma.toLowerCase())
    if (atributos.editar_proceso_firma)
    config.enableEdit = JSON.parse(atributos.editar_proceso_firma.toLowerCase())
    if(atributos.oficina && atributos.oficina != 0)
    localStorage.setItem('GRUPO_INTERNO',atributos.oficina)

    localStorage.setItem('CONFIG', JSON.stringify(config))
  }

  obtenerGrupoInterno():any {
    return localStorage.getItem('GRUPO_INTERNO')
  }

  obtenerRestricciones():any {
    return localStorage.getItem('CONFIG')
  }

  // Roles
  guardarRoles(atributos):void {
    localStorage.setItem('ROLES', atributos.rol)
  }

  guardarRolesArreglo(atributos):void {

    if(atributos.roles){
      localStorage.setItem('ROLES_ARRAY', JSON.stringify(atributos.roles))
    }else{
      localStorage.setItem('ROLES_ARRAY', JSON.stringify([]))
    }
    
   // localStorage.setItem('ROLES_ARRAY', atributos.roles)
  }
  obtenerRoles():string {
    return localStorage.getItem('ROLES')
  }
  obtenerRolesArreglo():any[] {
    return JSON.parse(localStorage.getItem('ROLES_ARRAY'))
  }

  /**
   * Keeps auto logout on page refresh.
   */
  keepAutoLogout(): void {
    console.log("keepAutoLogout")
    const expiration = localStorage.getItem('expiration');
    if (!expiration) {
      this.logout();
    }
    
    const tokenExpirationDate = new Date(expiration);
    if (!tokenExpirationDate || new Date() > tokenExpirationDate) {
      this.logout();
    } else {
      const expirationDuration = 
        tokenExpirationDate.getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  async handleTokenExpiration(): Promise<void> {
    console.log("handleTokenExpiration")
    const credentials = await Auth.currentUserCredentials();
    const expiration = credentials['expiration'];
    console.log("###########################",expiration)
    //const expiration =  this.addHours(new Date(),1)
    if (expiration) {
      const tokenExpirationDate = new Date(expiration);
      this.autoLogout(tokenExpirationDate.getTime() - new Date().getTime());
      localStorage.setItem('expiration', expiration.toString());
    }
  }

  addHours (date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  };

  async logout(): Promise<void> {
    console.log("logout")
    await Auth.signOut({ global: true });
    this.router.navigate(['/login']);
    localStorage.clear();
    this.refreshToken$.next(null);

    if (this.IDExpirationTimer) {
      clearTimeout(this.IDExpirationTimer);
    }
    this.IDExpirationTimer = null;
  }

  /**
   * Auto logout whenever the token expire.
   * @param expirationDuration the time of milliseconds we have until
   * te token is invalid.
   */
   private autoLogout(expirationDuration: number): void {
    console.log("autoLogout")
    const timeout = 30 * 1000;

    if (this.IDExpirationTimer) {
      clearTimeout(this.IDExpirationTimer);
    }
  
    this.IDExpirationTimer = setTimeout(() => {
      this.timeLeft$.next({
        timeLeft: timeout / 1000,
        idTimer: this.IDExpirationTimer
      });
    }, expirationDuration - timeout);
  }
}
