<input type="file" #fileInputMulti multiple (change)="change($event)">
<input type="file" #fileInput (change)="change($event)">

<div [class.less-padding]="lessPadding" class="dropzone" [class.dropzoneMitsui]="flagSubdomainMasa" (click)="handleClick()" (drop)="drop($event)" (dragover)="allowDrop($event)">

    <div class="text" [class.textMitsui]="flagSubdomainMasa">
        <i class="las la-file-invoice" [class.dropzoneIconMitsui]="flagSubdomainMasa"></i>
        <span>{{placeholder}}</span>
    </div>

</div>
