<div class="mega-container mb-2" [class.mega-container-Mitsui]="flagSubdomainMasa">

    <div *ngIf="!buscarMitsui" class="mega-filter" [class.mega-filter-Mitsui]="flagSubdomainMasa" tabindex="-1" (click)="openMegaFilter($event)" (keydown)="keyControls($event)">
        <span>
            <i class="las la-search"></i>
            <span *ngIf="checkFiltersOn()">{{ placeholder }}</span>
        </span>

        <div *ngFor="let filter of filters; index as i">
            <div *ngIf="!filter.on" class="filter" (click)="editFilter($event, i, filter.type, filter.value)">
                <span class="title">{{ filter.name }}:</span>
                <span class="value">{{ filter.value }}</span>
                <span class="icon" (click)="rmFilter($event, i, filter.col, filter.type)">
                    <i class="las la-times"></i>
                </span>
            </div>
        </div>

        <div class="clear-filters" (click)="clearFilters($event)" *ngIf="!checkFiltersOn()">
            <i class="las la-times"></i>
        </div>

    </div>

    <div *ngIf="buscarMitsui">
        <div (click)="$event.stopPropagation()">
            <div class="d-flex" *ngFor="let filter of filters; index as i">                
                <input class="inputMitsui" [class.inputMitsui-disabled]="!filter.on"
                autofocus (click)="selectType($event, filter.type, i);" 
                type="text" [placeholder]="activeFilter >= 0 ? filters[activeFilter].name : 'Buscar...'"
                [(ngModel)]="theValue" (keyup)="setFilterKeyup($event)">
    
                <!--<div *ngFor="let filter of filters; index as i">
                    <div *ngIf="!filter.on" class="filter" [class.filterMitsui]="buscarMitsui" (click)="editFilter($event, i, filter.type, filter.value)">
                        <span class="icon" [class.filterMitsui-icon]="buscarMitsui" (click)="rmFilter($event, i, filter.col, filter.type)">
                            <i class="las la-times"></i>
                        </span>
                    </div>
                </div>-->

                <div class="filter" [class.filterMitsui]="buscarMitsui">
                    <span class="icon" [class.filterMitsui-icon-search]="buscarMitsui">
                        <i class="las la-search" [class.iconMitsui]="buscarMitsui"></i>
                    </span>
                </div>
                
            </div>
        </div>
    </div>

    <div [hidden]="!openFilter || checkFiltersOff()" class="filter-dropdown">
        <h5 *ngIf="!hideMitsui" class="title">Agregar Filtro</h5><!--HOY MISTUI-->
        <ul>
            <li #list *ngFor="let filter of filters; index as i" [class.active]="activeFilter == i"
                (click)="selectType($event, filter.type, i);" [hidden]="filter.on == false">
                {{ filter.name }}
            </li>
        </ul>
    </div>

    <div *ngIf="!buscarMitsui" [hidden]="!type" class="filter-input" (click)="$event.stopPropagation()" [class.date-filter]="type == 'date'">

        <h5 class="title">
            {{ activeFilter >= 0 ? filters[activeFilter].name : null }}
        </h5>

        <div *ngIf="type == 'text'" class="d-flex">
            <input 
            autofocus 
            type="text" [placeholder]="activeFilter >= 0 ? filters[activeFilter].name : null"
            [(ngModel)]="theValue">
            <button class="success" (click)="setFilter(this.theValue)"><i class="las la-check"></i></button>
            <button class="error" (click)="theValue='';type=''"><i class="las la-times"></i></button>
        </div>

        <select 
        *ngIf="type == 'select'" 
        placeholder="Select" (change)="setFilter($event)" 
        [(ngModel)]="theValue">
            <option disabled selected>Seleccione...</option>
            <option *ngFor="let op of filters[activeFilter].options; index as i"
                [value]="op[filters[activeFilter].optionValue]">{{op[filters[activeFilter].optionName]}}</option>
        </select>

        <!-- <ng-container *ngIf="type == 'autocomplete'">
            <ng-autocomplete [data]="filters[activeFilter].options" searchKeyword="filters[activeFilter].optionName"
                [notFoundTemplate]="notFoundTemplate" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" notFoundText="Sin resultados."
                (selected)="setFilter($event[filters[activeFilter].optionValue])">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item[filters[activeFilter].optionName]"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
        </ng-container> -->

        <div [hidden]="type != 'date'" class="input-container mb-0 text-center">
            <app-datepicker #date [(ngModel)]="theDate" (selection)="setFilter($event)"></app-datepicker>
        </div>

        <div [hidden]="type != 'later-than'" class="input-container mb-0 text-center">
            <app-datepicker #date [(ngModel)]="theDate" (selection)="setFilter($event)"></app-datepicker>
        </div>

        <div [hidden]="type != 'earlier-than'" class="input-container mb-0 text-center">
            <app-datepicker #date [(ngModel)]="theDate" (selection)="setFilter($event)"></app-datepicker>
        </div>

    </div>

</div>