import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IAddSign } from '../holographic/models/add-sign.model';


type requestBody = {
    method: string
    data?: any,
    id?:any,
    idSigningProcess?: string,
    idCompany?: string,
    user?: string,
    nombres?:string,
    subdomain?:string
}


@Injectable({
    providedIn: 'root'
})
export class ServiciosProcesoService {

    private finaldata = [];
    //private apiurl = "https://jsonplaceholder.typicode.com/photos";
    private urlServiciosProceso:string = `${environment.apiCore}/process-services/`;
    private urlProcesoFirmaMasiva:string = `${environment.apiCore}/start-sign-massive/`;
    private urlDescribeExecution:string = `${environment.apiCore}/describe-execution/`;
    private urlProcesoFirmaIndividual:string = `${environment.apiCore}/start-sign-individual/`;

    constructor( private http: HttpClient ) { }

    listarPendientes(empresa:string, user:string, subdomain:string,metadata:any):Observable<any> {
        const body:requestBody = { method: 'LIST-PENDING', data: {idCompany:empresa, user:user, subdomain:subdomain, metadata:metadata} }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    listarPendientesPaginado(empresa:string, user:string, subdomain:string,metadata:any,offset:number,row_count:number, filters:any):Observable<any> {
        const body:requestBody = { method: 'LIST-PENDING-PAGINATION', data: {idCompany:empresa, user:user, subdomain:subdomain, metadata:metadata,offset:offset, row_count:row_count, filters:filters} }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    listarDocumentos(proceso:string, user:string, sub:string):Observable<any> {
        const body:requestBody = { method: 'LIST-VIEW-DOCUMENTS', data: {idSigningProcess:proceso, user:user, subdomain:sub} }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    listarDocumentosUsuarios(id:string, user:string):Observable<any> {
        const body:requestBody = { method: 'LIST', id:id, user:user }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    obtenerProcesoFirma(proceso:string, company:string, user:string, sub:string) {
        const body:requestBody = { method: 'GET-SIGNING-PROCESS', data: { idSigningProcess: proceso, idCompany:company, user:user, subdomain:sub } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    validarOrden(id:string, user:string) {
        const body:requestBody = { method: 'VALIDATE-SIGNED', data: { idProcess: id, user: user } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    enviarToken(empresa:number, user:string,name:string) {
        const body:requestBody = { method: 'SEND-TOKEN', data: { idCompany:empresa, user:user,nombres:name } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    validarToken(data:any) {
        const body:requestBody = { method: 'VALIDATE-TOKEN', data: data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    validarTokenPromise(data:any) {
        const body:requestBody = { method: 'VALIDATE-TOKEN', data: data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
    }

    comprobarCertificadoExiste(empresa:any, user:string) {
        const body:requestBody = { method: 'GET-CERTIFICATE-DIGITAL', data: { idCompany:empresa, user:user } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    agregarCertificado(data:any) {
        const body:requestBody = { method: 'ADD-CERTIFICATE-DIGITAL', data: data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    convertirPDF(data:{idProcess:string,filePath:string,idDocumentSigningProcess:number,idCompany:string}) {
        const body:any = { method: 'CONVERT-PDF-IMAGE', data:data }
        return this.http.post(this.urlProcesoFirmaMasiva, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    convertirPDFImagePagination(data:{idProcess:string,filePath:string,idDocumentSigningProcess:number,idCompany:string,count_page:number}) {
        const body:any = { method: 'CONVERT-PDF-IMAGE-PAGINATION', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    /*getData() {
      return this.http.get(this.apiurl);
    }

    getDataScroll(data:{pages:string}) {
        const bodyPages: any = { method: 'PAGES-IMAGE', data:data }
        return this.http.post(this.urlServiciosProceso, bodyPages);
    }*/
  
    convertPDFHolograhic(
        data: {
            idProcess: string,
            filePath: string,
            idDocumentSigningProcess: number,
            idCompany: string,
            flag: string
        }
    ) {
        const body:any = { method: 'CONVERT-PDF-IMAGE', data:data }
        return this.http.post<{ response: string[]}>(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    
    convertirPDFPromise(data:{idProcess:string,filePath:string,idDocumentSigningProcess:number,idCompany:string}) {
        const body:any = { method: 'CONVERT-PDF-IMAGE', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
    }
    
    agregarFirmaHolografica(data:any) {
        const body:any = { method: 'ADD-HOLOGRAPHIC', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    
    agregarFirmaHolograficaPromise(data:any) {
        const body:any = { method: 'ADD-HOLOGRAPHIC', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
    }

    agregarFacial(data:any) {
        const body:any = { method: 'ADD-FACIAL', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    
    agregarAnotacion(data:any) {
        const body:any = { method: 'ADD-ANNOTATIONS', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    addAnnotation(data: IAddSign) {
        const body = { method: 'ADD-ANNOTATIONS', data };
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    agregarAnotacionPromise(data:any) {
        const body:any = { method: 'ADD-ANNOTATIONS', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
    }

    agregarVisado(data:any) {
        const body:any = { method: 'ADD-VISADO', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    agregarVisadoPromise(data:any) {
        const body:any = { method: 'ADD-VISADO', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } }).toPromise()
    }

    devolverProceso(id:number,dominio:string,obs:string,user:string){
        const body:requestBody = { method: 'PROCESS-RETURN', data: { subdomain:dominio, observation:obs,user:user,idSigningProcess:id } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })

    }

    enviarCorreoContactarme(data:any) {
        const body:any = { method: 'SEND-EMAIL-NOTICE', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    obtenerDocumentoUuid(id:string,code:string){
        const body:requestBody = { method: 'GET-DOCUMENT-UUID', data: { id:id, code:code } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    obtenerDocumentoUuidAutomatic(id:string){
        const body:requestBody = { method: 'GET-DOCUMENT-UUID-AUTOMATIC', data: { id:id} }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    obtenerDocumentoCodeAndPass(code:string,password:string){
        const body:requestBody = { method: 'GET-DOCUMENT-CODE-PASS', data: { code:code, password:password } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })

    }

    obtenerDocumentoOldQr(code:string, subdominio:string){
        const body:requestBody = { method: 'GET-DOCUMENT-OLD-QR', data: { code:code, subdominio:subdominio } }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })

    }

    getSignedStatus(data: { idProcess: number, idDocumentSigningProcess: number }) {
        const body: any = { method: 'VIEW-DOCUMENT-SIGNED', data };
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } });
    }

    /** */
    executionArn(obj){
        return this.http.post(this.urlDescribeExecution, obj, { headers: { "x-api-key": environment.xApiKey } })
    }
    agregarCertificadoFirmaMasiva(data:any) {
        const body:requestBody = { method: 'ADD-CERTIFICATE-DIGITAL', data: data }
        return this.http.post(this.urlProcesoFirmaMasiva, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    validarTokenFirmaMasiva(data:any) {
        const body:requestBody = { method: 'VALIDATE-TOKEN', data: data }
        return this.http.post(this.urlProcesoFirmaMasiva, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    agregarFacialFirmaMasiva(data:any) {
        const body:any = { method: 'ADD-FACIAL', data:data }
        return this.http.post(this.urlProcesoFirmaMasiva, body, { headers: { "x-api-key": environment.xApiKey } })
    }
    itentosDesafioVida(data:any) {
        const body:any = { method: 'LIVENESS-ATTEMPS', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    agregarFirmaHolograficaIndividual(data:any) {
        const body:any = { method: 'ADD-HOLOGRAPHIC', data:data }
        return this.http.post(this.urlProcesoFirmaIndividual, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    measuresDocument(data:any) {
        const body:any = { method: 'MEASURES-DOCUMENT', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }

    measuresDocumentReceiver(data:any) {
        const body:any = { method: 'MEASURES-DOCUMENT-RECEIVER', data:data }
        return this.http.post(this.urlServiciosProceso, body, { headers: { "x-api-key": environment.xApiKey } })
    }
}