import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';

type  RequestBody = {
  method: string
  data?: any,
  id?: string,
  idCompany?: string
}

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private urlEmpresa:string = `${environment.apiCore}/company/`;

  constructor( private http: HttpClient ) { }

  obtenerEmpresaActiva(id:string):Observable<any> {
    const body: RequestBody = { method: 'LIST', id:id }
    return this.http.post(this.urlEmpresa, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  informacionEmpresa(id:string):Observable<any> {
    const body: RequestBody = { method:"GET-FLAG-COMPANY", id:id}
    return this.http.post(this.urlEmpresa ,body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerEmpresa(id:string):Observable<any> {
    const body: RequestBody = { method: 'ONE-COMPANY', id:id }
    return this.http.post(this.urlEmpresa, body, { headers: { "x-api-key": environment.xApiKey } })
  }
}
