<ng-container *ngIf="readonly">
    <div 
    *ngIf="type !== 'checkbox'"
    class="input-container disabled" [class.containerMitsui]="true">
        <label [class.labelDisabledMitsui]="true">{{label}}</label>
        <div class="display-disabled">{{ disabledTextValue ? disabledTextValue : value}}</div>
        <input 
            type="hidden"
            readonly
            disabled
            [class.inputSelectMitsui]="true"
            (focus)="$event.preventDefault()"                      
            (click)="$event.preventDefault()"
            (change)="$event.preventDefault()"
            (keyDown)="$event.preventDefault()"
            (keyUp)="$event.preventDefault()"
            (keyPress)="$event.preventDefault()"
            [(ngModel)]="value"
        >
    </div>
    <div
    *ngIf="type === 'checkbox'"
    class="input-container checkbox disabled"
    [class.containerMitsui]="true"
    [class.no-margins]="noMargins"
    [class.terms]="terms">
        <label [class.labelMitsui]="true" (click)="$event.preventDefault()">
            <input 
            type="checkbox" 
            [class.inputSelectMitsui]="true"
            (change)="$event.preventDefault()" 
            [(ngValue)]="value" [checked]="value">
            <span></span>
            {{label}}
        </label>
    </div>
</ng-container>

<ng-container *ngIf="!readonly" [ngSwitch]="type">

    <ng-container *ngSwitchCase="'select'">
        <div 
        class="input-container"
        [class.containerMitsui]="true"
        [class.no-margins]="noMargins"
        [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
            <label [class.labelMitsui]="true">{{label}}<span class="red" *ngIf="required"> *</span></label>
            <select 
            #element
            [class.inputSelectMitsui]="true"
            [(ngModel)]="value"
            (change)="handleChange($event)"
            (blur)="onTouchedCallback()">
                <option [value]="" disabled selected>Seleccione...</option>
                <option 
                *ngFor="let o of options" 
                [value]="optionsKey ? o[optionsKey] : o">
                {{optionsName ? o[optionsName] : o}}</option>
            </select>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'selectOnboarding'">
        <div 
        class="input-container"
        [class.no-margins]="noMargins"
        [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
            <label>{{label}}<span class="red" *ngIf="required"> *</span></label>
            <select style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            #element 
            [(ngModel)]="value"
            (change)="handleChange($event)"
            (blur)="onTouchedCallback()">
                <option [value]="" disabled selected>Seleccione...</option>
                <option 
                *ngFor="let o of options" 
                [value]="optionsKey ? o[optionsKey] : o">
                {{optionsName ? o[optionsName] : o}}</option>
            </select>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'selectOnboardingSize'">
        <div 
        class="input-container"
        [class.no-margins]="noMargins"
        [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
            <label>{{label}}<span class="red" *ngIf="required"> *</span></label>
            <select style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            #element 
            size="2"
            [(ngModel)]="value"
            (change)="handleChange($event)"
            (blur)="onTouchedCallback()">
                <option [value]="" disabled selected>Seleccione...</option>
                <option 
                *ngFor="let o of options" 
                [value]="optionsKey ? o[optionsKey] : o">
                {{optionsName ? o[optionsName] : o}}</option>
            </select>
        </div>
    </ng-container>

    <div
    *ngSwitchCase="'checkbox'"
    class="input-container checkbox"
    [class.containerMitsui]="true"
    [class.no-margins]="noMargins"
    [class.terms]="terms"
    [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label [class.labelMitsui]="true">
            <input type="checkbox" [class.inputSelectMitsui]="true" (change)="handleChange($event)" [(ngValue)]="value" [checked]="value">
            <span></span>
            {{label}}
        </label>
    </div>

    <div 
    *ngSwitchCase="'textarea'"
    class="input-container"
    [class.no-margins]="noMargins"
    [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label>{{label}}<span class="red" *ngIf="required"> *</span></label>
        <textarea 
        #element
        [(ngModel)]="value"
        (keyup)="handleChange($event)"
        (blur)="onTouchedCallback()"></textarea>
    </div>

    <div 
    *ngSwitchCase="'file'"
    class="input-container file"
    [class.error]="((ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid) || formatError">
        <label>{{label}}<span class="red" *ngIf="required"> *</span></label>
        <input
        #element
        type="file"
        (change)="handleFileChange($event)"
        [accept]="accept">
        <span class="file-input-display" (click)="fileInputClick()">{{file?.name}}</span>
        <i class="las la-folder-plus"></i>
    </div>

    <div 
    *ngSwitchDefault
    class="input-container" 
    [class.containerMitsui]="true"
    [class.no-margins]="noMargins"
    [class.disabled]="readonly || disabled"
    [class.error]="(ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid">
        <label [class.labelMitsui]="true">{{label}}<span class="red" *ngIf="required"> *</span></label>
        <input 
            #element 
            [class.inputSelectMitsui]="true"
            [autocomplete]="autocomplete"
            [placeholder]="placeholder"
            [type]="type" 
            [(ngModel)]="value"
            (keyup)="handleChange($event)"
            (blur)="onTouchedCallback()"
            (input)="onInput($event)"
            [step]="step"
            [readonly]="readonly"
            [disabled]="disabled">
    </div>
</ng-container>

<div class="error-block" *ngIf="((ngControl.control.touched || ngControl.control.dirty) && ngControl.control.invalid) || formatError">
    <p *ngIf="ngControl.control.errors?.required">Este campo es obligatorio</p>
    <p *ngIf="ngControl.control.errors?.email">Correo electrónico inválido</p>
    <p *ngIf="ngControl.control.errors?.min">El valor está por debajo del requerido</p>
    <p *ngIf="ngControl.control.errors?.mismatch">Las contraseñas deben coincidir</p>
    <p *ngIf="ngControl.control.errors?.minlength">El valor debe tener un mínimo de {{ngControl.control.errors?.minlength.requiredLength}} caracteres</p>
    <p *ngIf="ngControl.control.errors?.maxlength">El valor no debe exceder los {{ngControl.control.errors?.maxlength.requiredLength}} caracteres</p>
    <p *ngIf="formatError">Formato de archivo inválido</p>
    <p *ngIf="ngControl.control.errors?.pattern">El valor no puede contener ningun de los siguientes caracteres: / \ : * " > < | </p>
</div>