import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Documento } from 'src/app/models/Documento.model';
import { Observable } from 'rxjs';

type RequestBody = {
  method: string
  data?: Documento,
  id?: string,
  idCompany?: string,
  subdomain?: string
}

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  private urlDocumentos:string = `${environment.apiCore}/document_type/`;

  constructor( private http: HttpClient ) { }

  listarDocumentos(empresa:string):Observable<any> {
    const body: RequestBody = { method: 'LIST', idCompany: empresa }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerDocumentosPorProceso(idProceso:string,idCompany:string,user:string,subdomain:string):Observable<any>{
    const body: RequestBody = { method: 'LIST-DOCUMENTS-PROCESS', data: {idProcessType:idProceso, idCompany:idCompany,user:user,subdomain:subdomain} }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosPorSubdominio(subdomain:string):Observable<any> {
    const body: RequestBody = { method: 'LIST-DOCUMENT-SUBDOMAIN', subdomain: subdomain }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosActivos(empresa:string):Observable<any> {
    const body: RequestBody = { method: 'LIST-ACTIVE', idCompany: empresa }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosActivosSinFirmFast(empresa:string):Observable<any> {
    const body: RequestBody = { method: 'LIST-ACTIVE-NOT-FIRMFAST', idCompany: empresa }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarDocumentosCompartidosActivos(subdomain:string):Observable<any> {
    const body: RequestBody = { method: 'LIST-SHARED-ACTIVE', subdomain: subdomain }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  crearDocumento(documento:Documento) {
    const body: RequestBody = { method: 'POST', data: documento }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerDocumento(id:string):Observable<Documento> {
    const body: RequestBody = { method: 'GET', id: id }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarDocumento(documento:Documento) {
    const body: RequestBody = { method: 'PUT', data: documento }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  deshabilitarDocumento(id:string) {
    const body: RequestBody = { method: 'DELETE', id:id }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  habilitarDocumento(id:string) {
    const body: RequestBody = { method: 'PATCH', id:id }
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  assignUsers(data: Documento): Observable<any> {
    const body: RequestBody = { method: 'ASIGNED-USER-DOCUMENT', data };
    return this.http.post(this.urlDocumentos, body, { headers: { "x-api-key": environment.xApiKey } });
  }
}
