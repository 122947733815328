<div class="table-container" (window:resize)="onResize()">

  <!--<table class="ng-tb" style="overflow:hidden;">
    <thead>
      <tr class="true-header">

        <th width="35" *ngIf="canExpand" class="expand-toggle">
        </th>

        <ng-container *ngFor="let column of ngTable.head; index as i">

          <th 
          *ngIf="!column.hide && !column.respHide"
          [class.checkbox]="column.checkbox"
          [class.icon]="column.icon"
          [class.orderable]="column.sorting === undefined || column.sorting == true"
          [class.switch]="column.switch"
          [class.email]="column.email"
          #trueHeader>

            <ng-container *ngIf="column.checkbox">
              <div class="input-container checkbox" [class.option-relative]="optionRelative" *ngIf="!checkUnique">
                <label class="d-inline">
                  <input type="checkbox" [class.option-relative]="optionRelative" (change)="checkall($event)">
                  <span class="d-inline-block"></span>
                </label>
              </div>
            </ng-container>

            {{ !column.checkbox ? column.title : null }}

            <div class="sorting" *ngIf="showIconSort">
              <span class="asc" [class.active]="column.order == 'ASC'" (click)="sort(i, 'ASC')"></span>
              <span class="desc" [class.active]="column.order == 'DESC'" (click)="sort(i, 'DESC')"></span>
            </div>
        
          </th>

        </ng-container>

        <th style="padding: 0;" width="4"></th>

      </tr>
    </thead>
  </table>-->
  
  <div *ngIf="!flagSubdomainMasa && !flagAdex" class="inner" [class.auto-height]="autoHeight">

    <table class="ng-tb" #tableRef>

      <thead>
        <tr class="true-header">
  
          <th width="35" *ngIf="canExpand" class="expand-toggle">
          </th>
  
          <ng-container *ngFor="let column of ngTable.head; index as i">
  
            <th 
            *ngIf="!column.hide && !column.respHide"
            [class.checkbox]="column.checkbox"
            [class.icon]="column.icon"
            [class.orderable]="column.sorting === undefined || column.sorting == true"
            [class.switch]="column.switch"
            [class.email]="column.email"
            #trueHeader>
  
              <ng-container *ngIf="column.checkbox">
                <div class="input-container checkbox" [class.option-relative]="optionRelative" *ngIf="!checkUnique">
                  <label class="d-inline">
                    <input type="checkbox" [class.option-relative]="optionRelative" (change)="checkall($event)">
                    <span class="d-inline-block"></span>
                  </label>
                </div>
              </ng-container>
  
              {{ !column.checkbox ? column.title : null }}
  
              <div class="sorting" *ngIf="showIconSort">
                <span class="asc" [class.active]="column.order == 'ASC'" (click)="sort(i, 'ASC')"></span>
                <span class="desc" [class.active]="column.order == 'DESC'" (click)="sort(i, 'DESC')"></span>
              </div>
          
            </th>
  
          </ng-container>
  
          <th style="padding: 0;" width="4"></th>
  
        </tr>
      </thead>
      
      <!--<thead>
        <tr class="sizing">

          <th width="35" class="sizing expand-toggle" *ngIf="canExpand">
          </th>

          <ng-container *ngFor="let column of ngTable.head; index as i">

            <th 
            *ngIf="!column.hide && !column.respHide"
            #sizing
            class="sizing"
            [class.switch]="column.switch"
            [class.email]="column.email"
            [class.checkbox]="column.checkbox"
            [class.icon]="column.icon"
            [class.orderable]="column.sorting === undefined || column.sorting == true">

              <ng-container *ngIf="!column.hide">

                <div class="sizing-container">
                  <ng-container *ngIf="column.checkbox">
                    <label class="d-inline" *ngIf="!checkUnique">
                      <input type="checkbox" [class.option-relative]="optionRelative" (change)="checkall($event)">
                      <span class="d-inline-block"></span>
                    </label>
                  </ng-container>
                  {{ !column.checkbox ? column.title : null }}

                  <div class="sorting">
                    <span class="asc" (click)="sort(i, 'ASC')"></span>
                    <span class="desc" (click)="sort(i, 'DESC')"></span>
                  </div>
                </div>

              </ng-container>

            </th>

          </ng-container>
  
        </tr>
      </thead>-->

      <tbody>

        <ng-container *ngIf="!noData else empty">

          <ng-container *ngFor="let row of ngTable.body; index as i">

            <tr>

              <td *ngIf="canExpand" class="expand expand-toggle" [class.expanded]="row.expanded">
                <button 
                (click)="expand(i)">
                  <i *ngIf="!row.expanded" class="las la-plus"></i>
                  <i *ngIf="row.expanded" class="las la-minus"></i>
                </button>
              </td>

              <ng-container *ngFor="let cell of row.cols; index as j">

                <td 
                *ngIf="!ngTable.head[j].hide && !ngTable.head[j].respHide"
                [class.checkbox]="cell.checkbox"
                [class.switch]="cell.switch"
                [class.email]="cell.email"
                [class.icon]="cell.icon">

                  <!-- CHECKBOX -->
                  <ng-container *ngIf="cell.checkbox">
                    <div class="input-container" [class.option-relative]="optionRelative">
                      <label class="d-inline">
                        <input 
                        type="checkbox" [class.option-relative]="optionRelative"
                        [checked]="cell.checked"
                        (change)="check($event, cell, row.cols[checkIndex][checkProp], i, j)">
                        <span class="d-inline-block"></span>
                      </label>
                    </div>
                  </ng-container>
                  <!-- CHECKBOX -->

                  <!-- SWITCH -->
                  <ng-container *ngIf="cell.switch">
                    <div class="input-container switch">
                      <label (click)="switch($event, cell.preventSwitch, cell.value, cell.action)">
                        <input type="checkbox" 
                        [checked]="cell.value">
                        <span>
                          <span></span>
                        </span>
                      </label>
                    </div>
                  </ng-container>
                  <!-- SWITCH -->
      
                  <!-- ICON -->
                  <ng-container *ngIf="cell.icon">

                    <!-- If Icon -->
                    <ng-container *ngIf="cell.iconClass">
      
                      <!-- If no dropdown -->
                      <ng-container *ngIf="cell.action">
                        <i *ngIf="cell.iconClass" [class]="cell.iconClass" (click)="cell.action()"></i>
                      </ng-container>
      

                      <!-- If dropdown -->
                      <ng-container *ngIf="cell.opts">
                        <div class="dropdown" [class.option-relative]="optionRelative" (click)="tableDrop($event, i)" 
                        [class.disabled]="cell.disableOption">

                        <div 
                        class="card-button" 
                        [class.dropped]="cellsOpened[i]" 
                        [class.top]="top">
                          <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                        </div>
        
                        <div 
                        class="drop-content square-top shrink" 
                        [class.show]="cellsOpened[i]" 
                        [class.top]="top">

                              <div class="drop-content-section actions">
                                  
                                  <ul class="action-list">
                                    <ng-container *ngFor="let opt of cell.opts">
                                      <li [class.disabled]="opt.disableOption" *ngIf="!opt.hide" (click)="awaitAction($event, opt.action, opt.disableOption)">
                                        <app-loader *ngIf="loaders[opt.loader]"></app-loader>
                                        <i *ngIf="opt.iconClass && !loaders[opt.loader]" [class]="opt.iconClass"></i>
                                        <img *ngIf="opt.imgSrc && !loaders[opt.loader]" [src]="opt.imgSrc">
                                        {{opt.title}}
                                        <span *ngIf="opt.alert && !loaders[opt.loader]" app-dot-new></span>
                                      </li>
                                    </ng-container>
                                  </ul>
      
                              </div>
                              
                        </div>

                        </div>
                      </ng-container>
                      
      
                    </ng-container>
                    
                  <img *ngIf="cell.imgSrc" [src]="cell.imgSrc" [class]="cell.imgClass" (click)="cell.action ? cell.action() : null">
      
                  </ng-container>
                  <!-- ICON -->

                  <!-- TAG -->
                  <ng-container *ngIf="cell.tag">
                    <span [class]="cell.tagClass">
                      <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                      {{cell.contents}}
                    </span>
                  </ng-container>
                  <!-- TAG -->

                  <!-- EDIT CONTENTS -->
                  <ng-container *ngIf="cell.editContents">
                    <div 
                    class="popover-container" 
                    (click)="$event.stopPropagation(); editTd(i, cell.form);">

                      <a class="text-info" style= "cursor:pointer">
                        <span>
                          {{ edited[i] || cell.contents }}
                        </span>
                      </a>

                      <p class="small muted m-0" *ngIf="cell.subtitle"><span class="badge-inline"></span>Correo Editado</p>
      
                      <div class="popover-box" *ngIf="popoversOpened[i]" [formGroup]="edit">
                        <div class="popover" (click)="$event.stopPropagation()">
                            
                          <div class="d-flex">
                            <div class="input-container" [class.error]="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">
                              <input
                              autofocus 
                              type="text" 
                              [formControlName]="cell.form.name"
                              placeholder=" "
                              (click)="$event.stopPropagation();">
                              <label>Editar Campo</label>
                            </div>
                            <button class="popover-btn success" (click)="$event.stopPropagation(); confirmEdit(i, cell.form.name, cell.action)"><i class="las la-check"></i></button>
                            <button class="popover-btn error" (click)="$event.stopPropagation(); popoversOpened[i] = false;"><i class="las la-times"></i></button>
                          </div>
                          <div class="help-block red">
                            <p class="mb-0" *ngIf="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">Formato de correo inválido</p> 
                          </div>
        
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EDIT CONTENTS -->

                  <!-- TEMPLATE -->
                  <ng-container 
                  *ngIf="cell.template"
                  [ngTemplateOutlet]="cell.template"
                  [ngTemplateOutletContext]="{$implicit: cell}">
                  </ng-container>
                  <!-- TEMPLATE -->
                  
                  <ng-container *ngIf="!cell.editContents && !cell.tag && cell.action">
                    <a *ngIf="cell.action" class="pointer" (click)="cell.action();">
                      {{cell.contents}}
                    </a>
                  </ng-container>
                  
                  <ng-container *ngIf="!cell.editContents && !cell.tag && !cell.action && cell.contents">
                    <span *ngIf="cell.alert" app-dot-new ></span>                      
                      {{ cell.contents }} 
                  </ng-container>
                  
                </td>

              </ng-container>
              
            </tr>

            <tr class="td-child" *ngIf="row.expanded">
              <td [attr.colspan]="shownLength">

                <ul class="nested">

                  <ng-container *ngFor="let cell of row.cols; index as j">
                    <li *ngIf="ngTable.head[j].hide || ngTable.head[j].respHide">
                      <span class="title">{{ ngTable.head[j].title }}</span>

                      <!-- SWITCH -->
                      <ng-container *ngIf="cell.switch">
                        <div class="input-container switch">
                          <label>
                            <input type="checkbox" 
                            (change)="switch($event, cell.preventSwitch, cell.action)"
                            [checked]="cell.value">
                            <span>
                              <span></span>
                            </span>
                          </label>
                        </div>
                      </ng-container>
                      <!-- SWITCH -->

                      <!-- ICON -->
                      <ng-container *ngIf="cell.icon">
          
                        <!-- If Icon -->
                        <ng-container *ngIf="cell.iconClass">
          
                          <!-- If no dropdown -->
                          <ng-container *ngIf="cell.action">
                            <i *ngIf="cell.iconClass" [class]="cell.iconClass" (click)="cell.action()"></i>
                          </ng-container>
          
                          <!-- If dropdown -->
                          <ng-container *ngIf="cell.opts">
                            <div class="dropdown" [class.option-relative]="optionRelative" (click)="tableDrop($event, i)">

                              <div 
                              class="card-button" 
                              [class.dropped]="!cellsOpened[i]" 
                              [class.top]="top">
                                <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                              </div>
              
                              <div 
                              class="drop-content square-top shrink" 
                              [class.top]="top" 
                              [class.show]="!cellsOpened[i]">
                                  <div class="drop-content-section actions">
                                      
                                    <ul class="action-list">
                                      <ng-container *ngFor="let opt of cell.opts">
                                        <li *ngIf="!opt.hide"  [class.disabled]="opt.disableOption" (click)="awaitAction($event, opt.action, opt.disableOption)">
                                          <app-loader *ngIf="loaders[opt.loader]"></app-loader>
                                          <i *ngIf="opt.iconClass && !loaders[opt.loader]" [class]="opt.iconClass"></i>
                                          <img *ngIf="opt.imgSrc && !loaders[opt.loader]" [src]="opt.imgSrc">
                                          {{opt.title}}
                                        </li>
                                      </ng-container>
                                    </ul>
          
                                  </div>
                              </div>
                              
                            </div>
                          </ng-container>
                          
          
                        </ng-container>
                        
          
                        <img *ngIf="cell.imgSrc" [src]="cell.imgSrc">
          
                      </ng-container>
                      <!-- ICON -->

                      <!-- TAG -->
                      <ng-container *ngIf="cell.tag">
                        <span [class]="cell.tagClass" #tag>
                          <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                          {{cell.contents}}
                        </span>
                      </ng-container>
                      <!-- TAG -->

                      <!-- EDIT CONTENTS -->
                      <ng-container *ngIf="cell.editContents">
                        <div 
                        class="popover-container" 
                        (click)="$event.stopPropagation(); editTd(i, cell.form);">

                          <a class="text-info" style= "cursor:pointer">
                            <span>
                              {{ edited[i] || cell.contents }}
                            </span>
                          </a>
          
                          <div class="popover-box" *ngIf="popoversOpened[i]" [formGroup]="edit">
                            <div class="popover" (click)="$event.stopPropagation()">
                                
                              <div class="d-flex">
                                <div class="input-container" [class.error]="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">
                                  <input
                                  autofocus 
                                  type="text" 
                                  [formControlName]="cell.form.name"
                                  placeholder=" "
                                  (click)="$event.stopPropagation();">
                                  <label>Editar Campo</label>
                                </div>
                                <button class="popover-btn success" (click)="$event.stopPropagation(); confirmEdit(i, cell.form.name, cell.action)"><i class="las la-check"></i></button>
                                <button class="popover-btn error" (click)="$event.stopPropagation(); popoversOpened[i] = false;"><i class="las la-times"></i></button>
                              </div>
                              <div class="help-block red">
                                <p class="mb-0" *ngIf="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">Formato de correo inválido</p> 
                              </div>
            
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <!-- EDIT CONTENTS -->

                      <span *ngIf="!cell.editContents && !cell.tag && !cell.hide">{{cell.contents}}</span>
                    </li>
                  </ng-container>

                </ul>
              </td>
            </tr>

          </ng-container>

        </ng-container>

        <ng-template #empty>
          <tr>
            <td class="empty" [attr.colspan]="shownLength">No hay registros</td>
          </tr>
        </ng-template>
        

      </tbody>
    </table>

  </div>

  <div *ngIf="flagSubdomainMasa || flagAdex" class="inner inner-resp" [class.auto-height]="autoHeight">
    
    <table class="ng-tb ng-tb-resp" #tableRef>

      <thead>
        <tr class="true-header">
  
          <th width="35" *ngIf="canExpand" class="expand-toggle">
          </th>
  
          <ng-container *ngFor="let column of ngTable.head; index as i">
  
            <th 
            *ngIf="!column.hide && !column.respHide"
            [class.checkbox]="column.checkbox"
            [class.icon]="column.icon"
            [class.orderable]="column.sorting === undefined || column.sorting == true"
            [class.switch]="column.switch"
            [class.email]="column.email"
            #trueHeader>
  
              <ng-container *ngIf="column.checkbox">
                <div class="input-container checkbox" [class.option-relative]="optionRelative" *ngIf="!checkUnique">
                  <label class="d-inline">
                    <input type="checkbox" [class.option-relative]="optionRelative" (change)="checkall($event)">
                    <span class="d-inline-block"></span>
                  </label>
                </div>
              </ng-container>
  
              {{ !column.checkbox ? column.title : null }}
  
              <div class="sorting" *ngIf="showIconSort">
                <span class="asc" [class.active]="column.order == 'ASC'" (click)="sort(i, 'ASC')"></span>
                <span class="desc" [class.active]="column.order == 'DESC'" (click)="sort(i, 'DESC')"></span>
              </div>
          
            </th>
  
          </ng-container>
  
          <th style="padding: 0;" width="4"></th>
  
        </tr>
      </thead>
      
      <!--<thead>
        <tr class="sizing">

          <th width="35" class="sizing expand-toggle" *ngIf="canExpand">
          </th>

          <ng-container *ngFor="let column of ngTable.head; index as i">

            <th 
            *ngIf="!column.hide && !column.respHide"
            #sizing
            class="sizing"
            [class.switch]="column.switch"
            [class.email]="column.email"
            [class.checkbox]="column.checkbox"
            [class.icon]="column.icon"
            [class.orderable]="column.sorting === undefined || column.sorting == true">

              <ng-container *ngIf="!column.hide">

                <div class="sizing-container">
                  <ng-container *ngIf="column.checkbox">
                    <label class="d-inline" *ngIf="!checkUnique">
                      <input type="checkbox" [class.option-relative]="optionRelative" (change)="checkall($event)">
                      <span class="d-inline-block"></span>
                    </label>
                  </ng-container>
                  {{ !column.checkbox ? column.title : null }}

                  <div class="sorting">
                    <span class="asc" (click)="sort(i, 'ASC')"></span>
                    <span class="desc" (click)="sort(i, 'DESC')"></span>
                  </div>
                </div>

              </ng-container>

            </th>

          </ng-container>
  
        </tr>
      </thead>-->

      <tbody>

        <ng-container *ngIf="!noData else empty">

          <ng-container *ngFor="let row of ngTable.body; index as i">

            <tr>

              <td *ngIf="canExpand" class="expand expand-toggle" [class.expanded]="row.expanded">
                <button 
                (click)="expand(i)">
                  <i *ngIf="!row.expanded" class="las la-plus"></i>
                  <i *ngIf="row.expanded" class="las la-minus"></i>
                </button>
              </td>

              <ng-container *ngFor="let cell of row.cols; index as j">

                <td 
                *ngIf="!ngTable.head[j].hide && !ngTable.head[j].respHide"
                [class.checkbox]="cell.checkbox"
                [class.switch]="cell.switch"
                [class.email]="cell.email"
                [class.icon]="cell.icon">

                  <!-- CHECKBOX -->
                  <ng-container *ngIf="cell.checkbox">
                    <div class="input-container" [class.option-relative]="optionRelative">
                      <label class="d-inline">
                        <input 
                        type="checkbox" [class.option-relative]="optionRelative"
                        [checked]="cell.checked"
                        (change)="check($event, cell, row.cols[checkIndex][checkProp], i, j)">
                        <span class="d-inline-block"></span>
                      </label>
                    </div>
                  </ng-container>
                  <!-- CHECKBOX -->

                  <!-- SWITCH -->
                  <ng-container *ngIf="cell.switch">
                    <div class="input-container switch">
                      <label (click)="switch($event, cell.preventSwitch, cell.value, cell.action)">
                        <input type="checkbox" 
                        [checked]="cell.value">
                        <span>
                          <span></span>
                        </span>
                      </label>
                    </div>
                  </ng-container>
                  <!-- SWITCH -->
      
                  <!-- ICON -->
                  <ng-container *ngIf="cell.icon">

                    <!-- If Icon -->
                    <ng-container *ngIf="cell.iconClass">
      
                      <!-- If no dropdown -->
                      <ng-container *ngIf="cell.action">
                        <i *ngIf="cell.iconClass" [class]="cell.iconClass" (click)="cell.action()"></i>
                      </ng-container>
      

                      <!-- If dropdown -->
                      <ng-container *ngIf="cell.opts">
                        <div class="dropdown" [class.option-relative]="optionRelative" (click)="tableDrop($event, i)" 
                        [class.disabled]="cell.disableOption">

                        <div *ngIf="!iconsOpt"
                        class="card-button" 
                        [class.dropped]="cellsOpened[i]" 
                        [class.top]="top">
                          <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                        </div>
        
                        <div *ngIf="!iconsOpt"
                        class="drop-content square-top shrink" 
                        [class.show]="cellsOpened[i]" 
                        [class.top]="top">

                              <div class="drop-content-section actions">
                                  
                                  <ul class="action-list">
                                    <ng-container *ngFor="let opt of cell.opts">
                                      <li [class.disabled]="opt.disableOption" *ngIf="!opt.hide" (click)="awaitAction($event, opt.action, opt.disableOption)">
                                        <app-loader *ngIf="loaders[opt.loader]"></app-loader>
                                        <i *ngIf="opt.iconClass && !loaders[opt.loader]" [class]="opt.iconClass"></i>
                                        <img *ngIf="opt.imgSrc && !loaders[opt.loader]" [src]="opt.imgSrc">
                                        {{opt.title}}
                                        <span *ngIf="opt.alert && !loaders[opt.loader]" app-dot-new></span>
                                      </li>
                                    </ng-container>
                                  </ul>
      
                              </div>
                              
                        </div>

                        <div *ngIf="iconsOpt" class="drop-content-opt d-flex">
                          <ng-container *ngFor="let opt of cell.opts">
                            <div *ngIf="!opt.hide"(click)="awaitAction($event, opt.action, opt.disableOption)">
                              <!--<app-loader *ngIf="loaders[opt.loader]"></app-loader>-->
                              <i *ngIf="loaders[opt.loader]" [class]="opt.iconClass"></i>
                              <i *ngIf="opt.iconClass && !loaders[opt.loader]" [class]="opt.iconClass" [class.disabled]="opt.disableOption" matTooltip="{{ opt.title }}"></i>
                              <img *ngIf="opt.imgSrc && !loaders[opt.loader]" [src]="opt.imgSrc">
                              <span *ngIf="opt.alert && !loaders[opt.loader]" app-dot-new></span>
                            </div>
                          </ng-container>
                        </div>
                       
                        </div>
                      </ng-container>
                      
      
                    </ng-container>
                    
                  <img *ngIf="cell.imgSrc" [src]="cell.imgSrc" [class]="cell.imgClass" (click)="cell.action ? cell.action() : null">
      
                  </ng-container>
                  <!-- ICON -->

                  <!-- TAG -->
                  <ng-container *ngIf="cell.tag">
                    <span [class]="cell.tagClass">
                      <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                      {{cell.contents}}
                    </span>
                  </ng-container>
                  <!-- TAG -->

                  <!-- EDIT CONTENTS -->
                  <ng-container *ngIf="cell.editContents">
                    <div 
                    class="popover-container" 
                    (click)="$event.stopPropagation(); editTd(i, cell.form);">

                      <a class="text-info" style= "cursor:pointer">
                        <span>
                          {{ edited[i] || cell.contents }}
                        </span>
                      </a>

                      <p class="small muted m-0" *ngIf="cell.subtitle"><span class="badge-inline"></span>Correo Editado</p>
      
                      <div class="popover-box" *ngIf="popoversOpened[i]" [formGroup]="edit">
                        <div class="popover" (click)="$event.stopPropagation()">
                            
                          <div class="d-flex">
                            <div class="input-container" [class.error]="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">
                              <input
                              autofocus 
                              type="text" 
                              [formControlName]="cell.form.name"
                              placeholder=" "
                              (click)="$event.stopPropagation();">
                              <label>Editar Campo</label>
                            </div>
                            <button class="popover-btn success" (click)="$event.stopPropagation(); confirmEdit(i, cell.form.name, cell.action)"><i class="las la-check"></i></button>
                            <button class="popover-btn error" (click)="$event.stopPropagation(); popoversOpened[i] = false;"><i class="las la-times"></i></button>
                          </div>
                          <div class="help-block red">
                            <p class="mb-0" *ngIf="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">Formato de correo inválido</p> 
                          </div>
        
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EDIT CONTENTS -->

                  <!-- TEMPLATE -->
                  <ng-container 
                  *ngIf="cell.template"
                  [ngTemplateOutlet]="cell.template"
                  [ngTemplateOutletContext]="{$implicit: cell}">
                  </ng-container>
                  <!-- TEMPLATE -->
                  
                  <ng-container *ngIf="!cell.editContents && !cell.tag && cell.action">
                    <a *ngIf="cell.action" class="pointer" (click)="cell.action();">
                      {{cell.contents}}
                    </a>
                  </ng-container>
                  
                  <ng-container *ngIf="!cell.editContents && !cell.tag && !cell.action && cell.contents">
                    <span *ngIf="cell.alert" app-dot-new ></span>                      
                      {{ cell.contents }} 
                  </ng-container>
                  
                </td>

              </ng-container>
              
            </tr>

            <tr class="td-child" *ngIf="row.expanded">
              <td [attr.colspan]="shownLength">

                <ul class="nested">

                  <ng-container *ngFor="let cell of row.cols; index as j">
                    <li *ngIf="ngTable.head[j].hide || ngTable.head[j].respHide">
                      <span class="title">{{ ngTable.head[j].title }}</span>

                      <!-- SWITCH -->
                      <ng-container *ngIf="cell.switch">
                        <div class="input-container switch">
                          <label>
                            <input type="checkbox" 
                            (change)="switch($event, cell.preventSwitch, cell.action)"
                            [checked]="cell.value">
                            <span>
                              <span></span>
                            </span>
                          </label>
                        </div>
                      </ng-container>
                      <!-- SWITCH -->

                      <!-- ICON -->
                      <ng-container *ngIf="cell.icon">
          
                        <!-- If Icon -->
                        <ng-container *ngIf="cell.iconClass">
          
                          <!-- If no dropdown -->
                          <ng-container *ngIf="cell.action">
                            <i *ngIf="cell.iconClass" [class]="cell.iconClass" (click)="cell.action()"></i>
                          </ng-container>
          
                          <!-- If dropdown -->
                          <ng-container *ngIf="cell.opts">
                            <div class="dropdown" [class.option-relative]="optionRelative" (click)="tableDrop($event, i)">

                              <div 
                              class="card-button" 
                              [class.dropped]="!cellsOpened[i]" 
                              [class.top]="top">
                                <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                              </div>
              
                              <div 
                              class="drop-content square-top shrink" 
                              [class.top]="top" 
                              [class.show]="!cellsOpened[i]">
                                  <div class="drop-content-section actions">
                                      
                                    <ul class="action-list">
                                      <ng-container *ngFor="let opt of cell.opts">
                                        <li *ngIf="!opt.hide"  [class.disabled]="opt.disableOption" (click)="awaitAction($event, opt.action, opt.disableOption)">
                                          <app-loader *ngIf="loaders[opt.loader]"></app-loader>
                                          <i *ngIf="opt.iconClass && !loaders[opt.loader]" [class]="opt.iconClass"></i>
                                          <img *ngIf="opt.imgSrc && !loaders[opt.loader]" [src]="opt.imgSrc">
                                          {{opt.title}}
                                        </li>
                                      </ng-container>
                                    </ul>
          
                                  </div>
                              </div>
                              
                            </div>
                          </ng-container>
                          
          
                        </ng-container>
                        
          
                        <img *ngIf="cell.imgSrc" [src]="cell.imgSrc">
          
                      </ng-container>
                      <!-- ICON -->

                      <!-- TAG -->
                      <ng-container *ngIf="cell.tag">
                        <span [class]="cell.tagClass" #tag>
                          <i *ngIf="cell.iconClass" [class]="cell.iconClass"></i>
                          {{cell.contents}}
                        </span>
                      </ng-container>
                      <!-- TAG -->

                      <!-- EDIT CONTENTS -->
                      <ng-container *ngIf="cell.editContents">
                        <div 
                        class="popover-container" 
                        (click)="$event.stopPropagation(); editTd(i, cell.form);">

                          <a class="text-info" style= "cursor:pointer">
                            <span>
                              {{ edited[i] || cell.contents }}
                            </span>
                          </a>
          
                          <div class="popover-box" *ngIf="popoversOpened[i]" [formGroup]="edit">
                            <div class="popover" (click)="$event.stopPropagation()">
                                
                              <div class="d-flex">
                                <div class="input-container" [class.error]="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">
                                  <input
                                  autofocus 
                                  type="text" 
                                  [formControlName]="cell.form.name"
                                  placeholder=" "
                                  (click)="$event.stopPropagation();">
                                  <label>Editar Campo</label>
                                </div>
                                <button class="popover-btn success" (click)="$event.stopPropagation(); confirmEdit(i, cell.form.name, cell.action)"><i class="las la-check"></i></button>
                                <button class="popover-btn error" (click)="$event.stopPropagation(); popoversOpened[i] = false;"><i class="las la-times"></i></button>
                              </div>
                              <div class="help-block red">
                                <p class="mb-0" *ngIf="edit.get(cell.form.name).invalid  && (edit.get(cell.form.name).dirty || edit.get(cell.form.name).touched)">Formato de correo inválido</p> 
                              </div>
            
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <!-- EDIT CONTENTS -->

                      <span *ngIf="!cell.editContents && !cell.tag && !cell.hide">{{cell.contents}}</span>
                    </li>
                  </ng-container>

                </ul>
              </td>
            </tr>

          </ng-container>

        </ng-container>

        <ng-template #empty>
          <tr>
            <td class="empty" [attr.colspan]="shownLength">No hay registros</td>
          </tr>
        </ng-template>
        

      </tbody>
    </table>

  </div>

  <div class="tb-footer" *ngIf="showPaginator">

    <div class="page-length">
      <select [(ngModel)]="pageLength" (change)="currentPage=1;buildPagination();size();setPageLength()">
        <option [ngValue]="8">8</option>
        <option [ngValue]="10">10</option>
        <option [ngValue]="20">20</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      Registros
    </div>

    <div class="info" *ngIf="filteredRows.length">
      Mostrando {{indexStart+1}} a {{indexEnd}} de {{ filteredRows.length }} filas
      <span *ngIf="body.length != filteredRows.length">(filtrados de {{body.length}} registros)</span>
    </div>

    <div class="pagination" *ngIf="filteredRows.length > 0">

      <span
      (click)="setPage(1)"
      title="'Primero'"
      [class.disabled]="currentPage == 1"
      class="page-nav prev">
       <i class="las la-step-backward"></i>
      </span>

      <span
      [class.disabled]="currentPage == 1"
      class="page-nav prev" 
      (click)="setPage(currentPage - 1)">
        <i class="las la-angle-left"></i>
      </span>

      <span 
      [class.current]="currentPage == num"
      class="page-nav number" 
      *ngFor="let num of visiblePages" 
      (click)="setPage(num)">{{num}}</span>

      <span 
      [class.disabled]="currentPage == numbersOfPages.length || numbersOfPages.length == 0"
      class="page-nav next" 
      (click)="setPage(currentPage + 1)">
        <i class="las la-angle-right"></i>
      </span>

      <span
      (click)="setPage(numbersOfPages[numbersOfPages.length-1])"
      title="'Último'"
      [class.disabled]="currentPage == numbersOfPages.length || numbersOfPages.length == 0"
      class="page-nav next">
        <i class="las la-step-forward"></i>
      </span>

    </div>

  </div>

  </div>

<ng-template #processingTable>Processing</ng-template>