<div class="wizard-nav" [class.wizard-nav-Mitsui]="flagSubdomainMasa">

    <div class="step-bar" [class.step-bar-Mitsui]="flagSubdomainMasa" [style.width.%]="barWidth">
        <div class="progress" [style.width.%]="progressWidth"></div>
    </div>

    <div 
    [class.done]="step.done"
    [class.disabled]="step.disabled || step.skip"
    [class.active]="active === i"
    (click)="setActive(i)"
    class="step-title" 
    *ngFor="let step of steps; index as i;">
        <div class="number" [class.numberMitsui]="flagSubdomainMasa">{{i+1}}</div>
        <div class="title" [class.titleMitsui]="flagSubdomainMasa">
            <div class="done" *ngIf="step.done"><i class="las la-check"></i></div>
            {{step.title}}
        </div>
    </div>

</div>

<div class="wizard-content" [class.wizard-content-Mitsui]="flagSubdomainMasa">
    <ng-content></ng-content>
</div>

<div class="wizard-footer" [class.wizard-footer-Mitsui]="flagSubdomainMasa">
    <button 
    (click)="back()"
    [class.wizard-button-Mitsui]="flagSubdomainMasa"
    [class.disabled]="active === 0"
    class="btn danger">Anterior</button>

    <button 
    *ngIf="!adexStep && active !== steps.length - 1"
    [class.wizard-button-Mitsui]="flagSubdomainMasa"
    [class.disabled]="disableNext"
    (click)="forward()"
    class="btn success">Siguiente</button>

    <!--<button 
    *ngIf="active !== steps.length - 1"
    [class.wizard-button-Mitsui]="flagSubdomainMasa"
    [class.disabled]="disableNext"
    (click)="forward()"
    class="btn success">Siguiente</button>-->

    <button 
    *ngIf="flagAdex && adexStep && active !== steps.length - 1"
    [class.wizard-button-Mitsui]="flagSubdomainMasa"
    [class.loading]="loading"
    (click)="onend.emit()"
    class="btn success">{{endText}}</button>

    <button 
    *ngIf="active === steps.length - 1"
    [class.wizard-button-Mitsui]="flagSubdomainMasa"
    [class.loading]="loading"
    (click)="onend.emit()"
    class="btn success">{{endText}}</button>

</div>
