import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


type requestBody = {
  method: string
  data?: any,
  id?: string,
  idCompany?: string

}


@Injectable({
  providedIn: 'root'
})
export class RolService {

  private urlRoles:string = `${environment.apiCore}/role/`;

  constructor( private http: HttpClient ) { }

  listarRoles(empresa:string):Observable<any> {
    const body:requestBody = { method: 'LIST', idCompany: empresa }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarUsuariosPorRol(role:number, sub:string, idCompany:string):Observable<any> {
    const body:requestBody = { method: 'LIST-USER-ROLE', data: { role:role, subdomain:sub, idCompany:idCompany } }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  listarUsuariosFree(idEmpresa:number):Observable<any> {
    const body:requestBody = { method: 'LIST-USER-FREE', data: { idCompany:idEmpresa} }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  actualizarUsuarioRolesPorEmpresa(email:string, idEmpresa:string, subdomain:string):Observable<any> {
    const body:requestBody = { 
      method: 'UPDATE-USER-COMPANY-ROLE', 
      data: { 
        email:email,
        idCompany:idEmpresa,
        subdomain:subdomain
      } 
    }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }

  obtenerRolesUsuario(email:string, idEmpresa:string, subdomain:string):Observable<any> {
    const body:requestBody = { 
      method: 'GET-ROLES-USER', 
      data: { 
        email:email,
        idCompany:idEmpresa,
        subdomain:subdomain
      } 
    }
    return this.http.post(this.urlRoles, body, { headers: { "x-api-key": environment.xApiKey } })
  }
}
