import { Component, OnInit, ViewChild, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { AuthService } from '@noe/services/auth.service';

type format = 'img' | 'pdf' | 'excel' | 'zip' | 'word';
@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
  host: { class: 'app-dropzone' }
})
export class DropzoneComponent implements OnInit {

  @ViewChild('fileInput') input:ElementRef
  @ViewChild('fileInputMulti') inputMulti:ElementRef

  @Input('placeholder') placeholder:string = 'Arrastre sus archivos hasta aquí, o haga click para cargar.'
  @Input('preview') preview:boolean = true
  @Input('formats') formats: format[] = ['img']
  @Input('multi') multi:boolean
  @Input('less-padding') lessPadding:boolean
  @Input('esFacial') esFacial:boolean = false
  @Input('esPersonalizacion') esPersonalizacion:boolean = false


  @Output()
  onconvert: EventEmitter<any> = new EventEmitter()
  @Output()
  ondrop: EventEmitter<any> = new EventEmitter()
  flagSubdomainMasa:boolean = false;

  constructor(  
    public notify: NotificationsService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;
  }

  handleClick() {
    this.multi ? this.inputMulti.nativeElement.click() : this.input.nativeElement.click()
  }

  allowDrop(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  drop(e) {
    e.preventDefault();
    let files = e.dataTransfer.files
    this.validate(files)
  }

  change(e) {
    let files = e.target.files
    this.validate(files)
  }
  
  validate(files:File[]) {
   
    let valid:boolean = false
    let counter:number = 0

    while (!valid && counter < files.length) {

      switch (files[counter].type) {
        case 'image/jpeg': case 'image/png':
          if (this.formats.includes('img') && files[counter].size <= 262150 && !this.esFacial){
            valid = true
          }else if(this.formats.includes('img') && this.esFacial && files[counter].size <= 1048576){
            valid = true
          }else if(this.formats.includes('img') && this.esPersonalizacion && files[counter].size <= 1048576){
            valid = true
          } 

          break
        
        case 'application/pdf':
          if (this.formats.includes('pdf')) valid = true
          break

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          valid = this.formats.includes('excel');
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          valid = this.formats.includes('word');
          break;
        case 'application/zip': case 'application/x-zip-compressed':
          if (this.formats.includes('zip')) valid = true
          break
      }
      counter ++
    }

    if (valid) {

      this.ondrop.emit(this.multi ? files : files[0])
      if (this.preview) this.getBase(files[0])
    } else {
     this.notify.info("No se puede cargar este archivo. validar nuevamente.")
    }

  }

  conversionLoading:boolean
  base:any
  getBase(img:any) {

    this.conversionLoading = true
    const reader = new FileReader
    reader.onload = e => {
      this.base = e.target.result
      this.conversionLoading = false
      this.onconvert.emit(this.base)
    }
    reader.readAsDataURL(img)
  
  }

  displaySize(size) {
    if (size < 1048576) {
      return (size / 1024).toFixed(2) + ' KB';
    } else {
      return (size / 1048576).toFixed(2) + ' MB';
    }
  }

}
