import { 
  Component,
  Input, 
  HostListener, 
  ViewChildren, 
  QueryList, 
  ElementRef, 
  Output, 
  EventEmitter
} from '@angular/core';
import { formatDate } from '@angular/common';

import { Filter } from 'src/app/models/Filter.model';
import { AuthService } from '@noe/services/auth.service';

@Component({
  selector: 'app-mega-filter',
  templateUrl: './mega-filter.component.html',
  styleUrls: ['./mega-filter.component.scss']
})
export class MegaFilterComponent {

  @Input() placeholder: string;
  @Input() filters: Filter[] = [];
  @Input() session: string;
  @Input() hideMitsui: boolean;
  @Input() buscarMitsui: boolean;

  @Output() onset = new EventEmitter<any>();
  @Output() remaingFilters = new EventEmitter<Filter[]>();

  @ViewChildren('list') el: QueryList<ElementRef>;

  @HostListener('document:click', ['event']) clickout(event) {
    if (this.openFilter) {
      this.openFilter = false;
    }
    this.type = null;
  }

  constructor(public authService: AuthService) { }

  openFilter:boolean
  searchTerm:string
  type:string

  theValue:string;
  theDate:Date = new Date();

  editing:number = -1;
  selectedfilterList = [];
  flagSubdomainMasa:boolean = false;

  applyFilters() {
    this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;

    if (sessionStorage.getItem(this.session)) {
      this.filters = JSON.parse(sessionStorage.getItem(this.session))
      this.filters.forEach( f => {
        if (!f.on) {
          this.onset.emit({text:f.value, prop:f.prop, col:f.col, exact:f.exact})
        }
      })
    }
  }

  applyFiltersCargos() {
    this.session = 'FILTROS_CARGOS';
    this.applyFilters();
  }

  applyFiltersProcesos() {
    this.session = 'FILTROS_PROCESOS';
    this.applyFilters();
  }

  applyFiltersFlujos() {
    this.session = 'FILTROS_FLUJOS';
    this.applyFilters();
  }

  applyFiltersDocumentos() {
    this.session = 'FILTROS_DOCUMENTOS';
    this.applyFilters();
  }

  applyFiltersPlantillas() {
    this.session = 'FILTROS_PLANTILLAS';
    this.applyFilters();
  }

  applyFiltersUsuarios() {
    this.session = 'FILTROS_USUARIOS';
    this.applyFilters();
  }

  applyFiltersReceptores() {
    this.session = 'FILTROS_RECEPTORES';
    this.applyFilters();
  }

  applyFiltersPerfiles() {
    this.session = 'FILTROS_PERFILES';
    this.applyFilters();
  }

  applyFiltersGruposExternos() {
    this.session = 'FILTROS_GRUPOS_EXTERNOS';
    this.applyFilters();
  }

  applyFiltersGruposInternos() {
    this.session = 'FILTROS_GRUPOS_INTERNOS';
    this.applyFilters();
  }

  applyFiltersPendientes() {
    this.session = 'FILTROS_PENDIENTES';
    this.applyFilters();
  }

  applyFiltersPapelera() {
    this.session = 'FILTROS_PAPELERA';
    this.applyFilters();
  }

  applyFiltersProcesoRapido() {
    this.session = 'FILTROS_PROCESO_RAPIDO';
    this.applyFilters();
  }

  applyFiltersPasoUno() {
    this.session = 'FILTROS_PASO_UNO';
    this.applyFilters();
  }

  applyFiltersPasoTres() {
    this.session = 'FILTROS_PASO_TRES';
    this.applyFilters();
  }

  applyFiltersPendientesPorNotificar() {
    this.session = 'FILTROS_PENDIENTES_POR_NOTIFICAR';
    this.applyFilters();
  }

  checkFiltersOn() {
    return this.filters.every( filter => filter.on );
  }
  checkFiltersOff() {
    return this.filters.every( filter => !filter.on );
  }

  openMegaFilter(event) {
    console.log('hideMitsui',this.hideMitsui)
    console.log('buscarMitsui',this.buscarMitsui)
    event.stopPropagation();
    this.openFilter = true;
    if (this.type) {
      this.type = null;
      this.editing = -1;
    }
  } 

  selectType(event, type, i) {
    event.stopPropagation();
    this.type = type;
    this.activeFilter = i;
    this.openFilter = false;
  }

  cancelChange() {
    this.filters[this.activeFilter].value = '';
    this.filters[this.activeFilter].on = true;
  }

  setFilter(event) {  
    
    if (event != '') {

      if (!this.type) {
        return false;
      }

      let value = '';

      if (this.type == 'date' || this.type == 'earlier-than' || this.type == 'later-than') {
        value = formatDate(event, 'dd/MM/yyyy', 'en_US')
      } else if (this.type == 'select') {
        value = event.target.value
      } else {
        value = event;
      }

      if (this.editing != -1) {
        this.filters[this.editing].value = value;
        this.editing = -1;
      } else {
        this.filters[this.activeFilter].value = value;
        this.filters[this.activeFilter].on = false;
      }

      sessionStorage.setItem(this.session, JSON.stringify(this.filters))

      let filter:any = {
        text: this.filters[this.activeFilter].value,
        prop: this.filters[this.activeFilter].prop, 
        col: this.filters[this.activeFilter].col,
        exact: this.filters[this.activeFilter].exact,
        epoch: undefined
      }
      if (this.type == 'date') {
        filter.date = event
      }
      if (this.type == 'earlier-than') {
        filter.type = this.type
        event.setUTCSeconds(59)
        event.setUTCMinutes(59)
        event.setUTCHours(23)
        filter.epoch = Date.parse(event)
      }
      if (this.type == 'later-than') {
        filter.type = this.type
        event.setUTCSeconds(0);
        event.setUTCMinutes(0);
        event.setUTCHours(0);
        filter.epoch = Date.parse(event);
      }
      this.onset.emit(filter);
      this.selectedfilterList.push(filter);

      this.type = null;

      if(!this.buscarMitsui) {
        this.theValue = '';
      }
    }
    
  }

  setFilterKeyup(event) {
    let value = '';
    
    value = event.target.value;

    if (value !== '') {
      if (event.code == 'Backspace' || event.code == 'Delete') {
        this.filters[this.activeFilter].value = '';
        this.onset.emit({text: '', prop: 'contents', col: -1});
      }
      
      this.filters[this.activeFilter].value = value;
      sessionStorage.setItem(this.session, JSON.stringify(this.filters))

      let filter:any = {
        text: this.filters[this.activeFilter].value,
        prop: this.filters[this.activeFilter].prop, 
        col: this.filters[this.activeFilter].col,
        exact: this.filters[this.activeFilter].exact,
        epoch: undefined
      }
  
      this.onset.emit(filter);
      this.selectedfilterList.push(filter);

    } else {
      this.filters[this.activeFilter].value = '';
      this.onset.emit({text: '', prop: 'contents', col: -1});
      sessionStorage.setItem(this.session, JSON.stringify(this.filters))
    }
  }

  rmFilter(event, i, col, type) {
    event.stopPropagation();
    this.filters[i].value = '';
    this.filters[i].on = true;
    this.selectedfilterList = this.selectedfilterList.filter((el) => {
      if (['earlier-than', 'later-than'].includes(type)) {
        return el.type !== type;
      }
      return el.col !== col;
    });

    this.onset.emit({
      text: '',
      prop: 'contents',
      col,
      remainFilters: this.selectedfilterList
    });

    if(this.buscarMitsui) {
      this.theValue = '';
    }

    sessionStorage.setItem(this.session, JSON.stringify(this.filters))
  }
  
  editFilter(event, i, type, value) {
    this.openFilter = false
    event.stopPropagation();
    this.editing = i;
    this.type = type;
    this.theValue = value;
  }

  activeFilter: number;
  keyControls(event) {
    
    if (event.keyCode == 38) {
      // UP
      this.setActive(1, -1)
    } else if (event.keyCode == 40) {
      // DOWN
      this.setActive(1, +1)
    } 
    
    
    if (event.keyCode == 13) {
      event.stopPropagation();
      event.preventDefault();
      this.selectType(event, this.filters[this.activeFilter].type, this.activeFilter);
    }
    
  }

  setActive(i, dir) {
    const check = this.activeFilter + dir;

    event.stopPropagation();
    event.preventDefault();

    if ( check >= 0 && check < this.filters.length ) {
      this.activeFilter = this.activeFilter + dir;
      this.el.toArray()[check].nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }

  clearFilters(e = null) {
    if (e) e.stopPropagation()
    Object.keys(this.filters).forEach(o => {
      this.filters[o].value = '';
      this.filters[o].on = true;
    });
    // this.setStrings();
    this.onset.emit({text: '', prop: 'contents', col: -1});
    sessionStorage.setItem(this.session, JSON.stringify(this.filters))
  }

  clearInputs() {
    this.openFilter = false;
  }

}
