import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@noe/services/auth.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input('steps') steps:any[] = []
  @Input('endText') endText:string = 'Finalizar'
  @Input('disableNext') disableNext:boolean
  @Input('loading') loading:boolean
  @Input('adexStep') adexStep:boolean
  @Input('flagAdex') flagAdex:boolean

  @Output() 
  onstep: EventEmitter<any> = new EventEmitter()
  @Output() 
  onend: EventEmitter<any> = new EventEmitter()
  flagSubdomainMasa:boolean = false;

  constructor(public authService: AuthService) { }

  barWidth:number
  progressWidth:number = 0
  ngOnInit(): void {
    this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;
    this.barWidth = (100 / this.steps.length) * (this.steps.length - 1)
  }

  active:number = 0
  setActive(index:number, dir: '+' | '-' = null) {
    // console.log('set active', index)
    if (!this.steps[index].skip) {
      this.active = index
      this.adjustProgress()
      this.onstep.emit(this.active)
    } else {
      const next:number = dir === '+' ? index + 1 : index - 1
      this.setActive(next, dir)
    }
  }

  forward() {
    this.active < this.steps.length - 1 ? this.setActive(this.active+1, '+') : null
    this.adjustProgress()
  }
  back() {
    this.active >= 0 ? this.setActive(this.active-1, '-') : null
    this.adjustProgress()
  }

  adjustProgress() {
    this.progressWidth = (100 / (this.steps.length - 1)) * this.active
  }

}
