import { 
  Component,
  OnInit, 
  Input, 
  Output, 
  EventEmitter, 
  HostListener, 
  ViewChildren, 
  ViewChild, 
  QueryList, 
  ElementRef, 
  AfterViewInit,
  DoCheck,
  Renderer2
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '@noe/services/auth.service';
import { EmpresaService } from '@noe/services/empresa.service';
import { PermisosService } from '@noe/services/permisos.service';
import { UsuariosService } from '@noe/services/usuarios.service';
import { Filter } from 'src/app/models/Filter.model';

import { TableTh } from 'src/app/models/Table.model';
import { TableTd } from 'src/app/models/Table.model';


@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements DoCheck ,OnInit, AfterViewInit {

  @ViewChildren('sizing') sizing:QueryList<ElementRef>;
  @ViewChildren('trueHeader') trueHeader:QueryList<ElementRef>;
  @ViewChild('tableRef') tableRef:ElementRef;

  @Input() head: TableTh[]
  @Input() body: Array<TableTd[]>
  @Input() autoHeight: boolean
  @Input() checkUnique: boolean
  @Input() loaders: boolean[] = [];
  @Input() showPaginator: boolean = true;
  @Input() showIconSort: boolean = true;
  @Input() numbersOfPagesPagination:number
  @Input() pageLength:number;
  @Input() numPageSelected:number;
  @Output() 
  getCheckedItems = new EventEmitter<any[]>()
  @Output() 
  onLoad = new EventEmitter<any>()
  @Output() onsetPagination = new EventEmitter<any>();

  @Output () cambio: EventEmitter<number> = new EventEmitter();
  @Input() iconsOpt: boolean = false;
  
  ngTable:any = {}
  filteredRows:any[][];
  optionRelative: boolean=false;
  flagSubdomainMasa:boolean = false;
  start:boolean = false;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService,
    public permisosService: PermisosService,
    public usuarioService: UsuariosService,
    public empresaService: EmpresaService
  ) { }

  setBody(body: Array<TableTd[]>): void {
    this.body = body;
  }
  
  ngOnInit() {
    this.filteredRows = this.body
    this.noData = this.filteredRows.length == 0;
    this.buildPagination()
    this.buildTable()

    if(this.numPageSelected>1){
      //this.displayPage(this.currentPage)
    }

    this.start = localStorage.getItem('AUTHSTART') === 'start'
    //console.log(this.start)

    if (this.start) {
      this.obteniendoDatos(localStorage.getItem('AUTHUSER'), localStorage.getItem('AUTHSUBDOMINIO'));
    }else{
      this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;
    }
  }

  obteniendoDatos(user, subdomain){
    this.usuarioService.obtenerUsuario(user, subdomain).subscribe(data => {
      console.log("TABLE PAGINATION 1",data)
      this.authService.guardarDatos(data)
      this.permisosService.setOpciones()
      this.authService.generarPermisos(data.profiles)
      this.empresaService.obtenerEmpresa(this.authService.obtenerIdEmpresaActiva()).subscribe(dataEmp => {
        console.log("TABLE PAGINATION 2",dataEmp)
        setTimeout(() => {
          this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;
        }, 1000)
      }, error => {
        console.log(error)
      })
    }, error => {
      console.log(error)
    })
  }

  ngDoCheck(): void {
    const noMetdataList = document.querySelectorAll('.no-metadata');
    
    noMetdataList.forEach((element) => {
      const siblingEl = element.previousElementSibling;
      this.renderer.setStyle(siblingEl, 'display', 'none');
    });
  }

  ngAfterViewInit(){
    
    setTimeout(() => {
      // this.setHidden()
      this.size()
      this.onLoad.emit(true)
    }, 5)

  }

  manualInit() {
    this.filteredRows = this.body
    this.filteredRows.length == 0 ? this.noData = true : this.noData = false
    this.buildPagination()
    this.buildTable()
    this.size()
  }

  onResize() {
    this.size()
    setTimeout(() => {this.size()},50)
  }

  canExpand:boolean
  shownLength:number = 1
  buildTable() {

    this.setHidden()
    this.displayPage(1)
    this.currentPage = this.numPageSelected != null ? this.numPageSelected : 1

  }

  /**
   * Clean the table.
   */
  clean(): void {
    this.head.splice(0);
    this.body.splice(0);
    this.filteredRows.splice(0);
    this.noData = true;
  }

  numbersOfPages:number[] = []

  currentPage:number = 1
  currentPageItems: any[]

  visibleStart:number = 1
  visibleLength:number = 7
  visiblePages:number[]

  buildPagination() {

    this.numbersOfPages = []

    const total = this.numbersOfPagesPagination
    let numbersOfPages
 
    numbersOfPages = Math.ceil(total / this.pageLength)
    
    for (let i:number = 1; i <= numbersOfPages; i++) {
      this.numbersOfPages.push(i)
    }

    if(this.numPageSelected > 1){
      this.setPagePagination(this.numPageSelected)
    }else{
      this.setVisiblePages(1, 1 + this.visibleLength)
    }
    //console.log("Me pintas",this.pageLength)
    //this.cambio.emit(this.pageLength)
  }

  setPageLength(){
    this.cambio.emit(this.pageLength)
  }

  noData:boolean = false
  setPage(num) {
    //console.log("click number page", num)
    if (this.filteredRows.length == 0) {

      this.noData = true
      this.visibleStart = 1
      this.currentPage = 1

    } else {

      this.noData = false

    }
    
    if (num > 0 && num <= this.numbersOfPages.length) {

      if ( num - 3 >= 1 && num + 3 <= this.numbersOfPages.length ) {
        this.visibleStart = num - 3
      } else {
        if ( num - 2 >= 1 && num + 2 <= this.numbersOfPages.length ) {

          if ( num - 2 + this.visibleLength <= this.numbersOfPages.length || num - 4 < 0 ) {
            this.visibleStart = num - 2
          } else {
            this.visibleStart = num - 4
          }

        } else if ( num - 1 >= 1 && num + 1 <= this.numbersOfPages.length ) {

          if ( num - 1 + this.visibleLength <= this.numbersOfPages.length || num - 5 < 0) {
            this.visibleStart = num - 1
          } else {
            this.visibleStart = num - 5
          }
        
        } else {
          if (num - this.visibleLength > 0) {
            this.visibleStart = num - this.visibleLength + 1
          } else {
            this.visibleStart = 1
          }
          
        }
      }

      this.currentPage = num

      this.onsetPagination.emit(num)

      if (this.numbersOfPages.length >= 7) {
        
        this.setVisiblePages(this.visibleStart, this.visibleStart + this.visibleLength)
      }
      
    }

  }

  setPagePagination(num) {
  
    if (this.filteredRows.length == 0) {

      this.noData = true
      this.visibleStart = 1
      this.currentPage = 1

    } else {

      this.noData = false

    }
    
    if (num > 0 && num <= this.numbersOfPages.length) {

      if ( num - 3 >= 1 && num + 3 <= this.numbersOfPages.length ) {
        this.visibleStart = num - 3
      } else {
        if ( num - 2 >= 1 && num + 2 <= this.numbersOfPages.length ) {

          if ( num - 2 + this.visibleLength <= this.numbersOfPages.length || num - 4 < 0 ) {
            this.visibleStart = num - 2
          } else {
            this.visibleStart = num - 4
          }

        } else if ( num - 1 >= 1 && num + 1 <= this.numbersOfPages.length ) {

          if ( num - 1 + this.visibleLength <= this.numbersOfPages.length || num - 5 < 0) {
            this.visibleStart = num - 1
          } else {
            this.visibleStart = num - 5
          }
        
        } else {
          if (num - this.visibleLength > 0) {
            this.visibleStart = num - this.visibleLength + 1
          } else {
            this.visibleStart = 1
          }
          
        }
      }

      if (this.numbersOfPages.length >= 7) {
        
        this.setVisiblePages(this.visibleStart, this.visibleStart + this.visibleLength)
      }
      
    }

  
  }


  indexStart:number
  indexEnd:number
  displayPage(page) {

    this.indexStart = ((page * this.pageLength) - this.pageLength)
    this.indexEnd = this.indexStart + this.pageLength
    
    this.currentPageItems = this.filteredRows.slice(this.indexStart, this.indexEnd)

    if (this.currentPageItems.length < this.pageLength) {
      this.indexEnd = this.indexStart + this.currentPageItems.length
    }

    const final = this.currentPageItems.map( (row, index) => {

      return {
        expanded: false,
        cols: row
      }

    })

    this.ngTable.body = final
  }

  setVisiblePages(start, end) {    
    start--
    end--
    this.visiblePages = this.numbersOfPages.slice(start, end)
  }


  // page resizing
  sizeCount:number = 0
  size() {
    this.canExpand = false
    this.setHidden()
    const th = this.trueHeader.toArray()
    const sz = this.sizing.toArray()
    const sizes = sz.map( s => s.nativeElement.clientWidth )

    th.forEach(
      (h, i) => {
        const pLeft = parseInt(window.getComputedStyle(h.nativeElement).paddingLeft.split('px')[0])
        const pRight = parseInt(window.getComputedStyle(h.nativeElement).paddingRight.split('px')[0])

        h.nativeElement.width = (sizes[i] - ( pLeft + pRight )) + 1 
        
      }
    )

    sz.forEach(
      (h, i) => {
        const pLeft = parseInt(window.getComputedStyle(h.nativeElement).paddingLeft.split('px')[0])
        const pRight = parseInt(window.getComputedStyle(h.nativeElement).paddingRight.split('px')[0])

        h.nativeElement.width = (sizes[i] - ( pLeft + pRight )) + 1
    
      }
    )

    this.sizeCount ++
    // console.timeEnd('SIZE')
  }

  // FILTERING
  columnFilters:any = []
  filterTable(filter: Filter): void {
    const {
      text, prop = 'contents', col, exact = false, type = null, epoch = null, remainFilters
    } =  filter;

    let initial = col === -1 || !!remainFilters && remainFilters.length === 0
      ? this.body
      : this.filteredRows;

    if (!!remainFilters && remainFilters.length > 0) {
      remainFilters.forEach((filter, index) => {
        const newFilter = { ...filter, remainFilters: null };
        
        if (index === 0) {
          this.filteredRows = this.body;
        }

        this.filterTable(newFilter);
      });
      return;
    }

    if (col == -1) {
      this.columnFilters.fill(null);
    } else {

      if (text == '') {
        this.columnFilters[col] = null
      } else {
        const search = text.toLowerCase();
        this.columnFilters = [];
        this.columnFilters[col] = { text: search, prop, exact, type, epoch };
      }

      this.columnFilters.forEach((f,i) => {
        if (f && f.text != '') {

          initial = initial.filter( row => {

            // filtro normal
            if (!f.epoch) {

              const rowText = row[i][f.prop] ? (row[i][f.prop]).toString().toLowerCase() : '';
            
              if (f.exact) {
                return rowText == f.text;
              }
              return rowText.indexOf(f.text) >= 0

            } else {
              // Fecha inicio / fecha fin
              const rowText = Date.parse(row[i][f.prop]);
              
              if (type === 'earlier-than') {
                return rowText <= f.epoch;
              } else {
                return rowText >= f.epoch;
              }
              
            }
          })
        }
      })
    }

    this.filteredRows = initial;

    this.setPage(1);
    this.buildPagination();
  }



  // CHECKBOXES 
  @Input() checkIndex:number
  @Input() checkProp:string = 'contents'
  checkedList: any[] = []
  // checksModel: boolean[] = []
  check(e, cell, el, i:number, j:number) {
    this.optionRelative = true;
    // this.checksModel[this.indexStart+i] = e.target.checked
    if (this.checkUnique) {
      this.body.forEach(r => { r.forEach(c => { if (c.checkbox) c.checked = false }) })
    }
    cell.checked = e.target.checked

    if (e.target.checked) {

      this.checkUnique ? this.checkedList = [el] : this.checkedList.push(el)

    } else {
      this.checkedList = this.checkedList.filter(item => item != el)
    }
    
    this.getCheckedItems.emit(this.checkedList)
  }

  checkall(e) {
    this.optionRelative = true;
    // this.checksModel.fill(e.target.checked)
    this.body.forEach(r => { r.forEach( c => { if (c.checkbox) c.checked = e.target.checked }) })

    if (e.target.checked) {
      this.checkedList = this.filteredRows.map(row => row[this.checkIndex][this.checkProp])
    } else {
      this.checkedList = []
    }

    this.getCheckedItems.emit(this.checkedList)
    
  }

  uncheckEverything() {
    this.body.forEach(r => { r.forEach( c => { if (c.checkbox) c.checked = false }) })
    this.checkedList = []
    this.getCheckedItems.emit(this.checkedList)
  }






  // Close dropdown on click outside
  cellsOpened: boolean[] = []
  popoversOpened: boolean[] = []
  @HostListener('document:click', ['$event']) clickout(event) {
    this.cellsOpened.fill(false);
    this.popoversOpened.fill(false)
  }

  top:boolean
  tableDrop(e, i) {
    this.optionRelative = true;
    e.stopPropagation()

    let height = this.tableRef.nativeElement.parentElement.clientHeight
    let clientRect = this.tableRef.nativeElement.parentElement.getBoundingClientRect().top

    let top = height-200 < e.clientY - clientRect
    top ? this.top = true : this.top = false

    if ( this.cellsOpened[i] == undefined ) {
      this.cellsOpened[i] = false
    }

    let current = this.cellsOpened[i]
    this.cellsOpened.fill(false)

    if (current == true) {
      this.cellsOpened[i] = true
    }
    this.cellsOpened[i] = !this.cellsOpened[i]

  }







  // ordering
  sort(i, dir) {

    const collator = new Intl.Collator('es', { numeric: true, sensitivity: 'base' });
    // arrayOfObjects.sort((a, b) => {
    //   return collator.compare(a.name, b.name);
    // });
    this.ngTable.head.forEach(th => th.order = '')
    if (dir == 'ASC') {
      this.filteredRows = this.filteredRows.sort((a, b) => {

        this.ngTable.head[i].order = 'ASC'

        if (a[i].date && b[i].date) {
          return (a[i].dateObj > b[i].dateObj) ? 1 : -1
        } else {
          return (collator.compare(a[i].contents, b[i].contents) >= 0) ? 1 : -1
        }

      })
    } else {
      this.filteredRows = this.filteredRows.sort((a, b) => {

        this.ngTable.head[i].order = 'DESC'

        if (a[i].date && b[i].date) {
          return (a[i].dateObj < b[i].dateObj) ? 1 : -1
        } else {
          return (collator.compare(a[i].contents, b[i].contents) <= 0) ? 1 : -1
        }
        
      })
    }
    
    this.setPage(1)
    this.buildPagination()
    
  }




  switch(e, preventSwitch, cvalue, action) {
    if (preventSwitch) e.preventDefault()
    if (action) action(!cvalue)
  }


  mediaQueriesMitsui: any = [
    {
      width: 0,
      items: 7
    },
    {
      width: 572,
      items: 7
    },
    {
      width: 768,
      items: 7
    },
    {
      width: 992,
      items: 7
    },
    {
      width: 1200,
      items: 7
    },
    {
      width: 1500,
      items: 7
    }
  ]

  mediaQueries: any = [
    {
      width: 0,
      items: 1
    },
    {
      width: 572,
      items: 3
    },
    {
      width: 768,
      items: 4
    },
    {
      width: 992,
      items: 5
    },
    {
      width: 1200,
      items: 6
    },
    {
      width: 1500,
      items: 7
    }
  ]

  setHidden() {
    // console.time('SET HIDDEN')

    let width
    // reset widths
    if (this.tableRef) {

      const th = this.trueHeader.toArray()
      const sz = this.sizing.toArray()

      th.forEach( t => { t.nativeElement.width = '' })
      sz.forEach( t => { t.nativeElement.width = '' })

      width = this.tableRef.nativeElement.clientWidth
    } else {

      width = this.hostElement.nativeElement.parentElement.clientWidth
    }

    // esconder elementos que queden por fuera
    let items

    // SET THE AMOUNT OF ITEMS 
    if(this.flagSubdomainMasa) {
      for (const q of this.mediaQueriesMitsui) {
        if (width > q.width) {
          items = q.items
        } else {
          break
        }
      }
    } else {
      for (const q of this.mediaQueries) {
        if (width > q.width) {
          items = q.items
        } else {
          break
        }
      }
    }

    let initialLength = 0
    this.head.forEach(th => { if (!th.hide) initialLength ++ })
    let diff = initialLength - items

    for ( let i = this.head.length-1; i >= 0; i-- ) {

      if (diff > 0) {
        if (!this.head[i].hide && !this.head[i].priority && !this.head[i].checkbox) {
          this.head[i].respHide = true
          diff --
        }
      } else {
        if (!this.head[i].hide) {
          this.head[i].respHide = false
        }
      }

    }
    

    for (const th of this.head) {
      if (th.hide || th.respHide) {
        
        this.canExpand = true
      } else {
        this.shownLength ++
      }
    }
    


    this.ngTable.head = this.head
    this.columnFilters.length = this.head.length
    // console.timeEnd('SET HIDDEN')
  }


  currentlyHidden:any
  expand(row) {
    const currentRow = this.ngTable.body[row]
    currentRow.expanded = !currentRow.expanded
  }





  // EDIT POPOVERS
  edited: string[] = []
  edit: FormGroup
  editTd(i, obj) {
    this.popoversOpened[i] = true
    let group = {}
    group[obj.name] = new FormControl('', [Validators[obj.validators]])
    this.edit = new FormGroup(group)
  }

  confirmEdit(i, input, action) {
    this.edited[i] = this.edit.value[input]
    this.popoversOpened[i] = false
    if (action) action(this.edited[i])
  }

  awaitAction(e, action, disabled:boolean) {
    if (disabled) return
    e.stopPropagation()
    action()
  }

}
