import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputComponent } from './input/input.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { ModalComponent } from './modal/modal.component';
import { LoaderComponent } from './loader/loader.component';
import { TableComponent } from './table/table.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { FormsModule } from '@angular/forms';
import { NotificationsComponent } from './notifications/notifications.component';
import { WizardComponent } from './wizard/wizard.component';
import { PasswordValidatorsComponent } from './password-validators/password-validators.component';
import { S3ImageComponent } from './s3-image/s3-image.component';
import { SwitchComponent } from './switch/switch.component';
import { MegaFilterComponent } from './mega-filter/mega-filter.component';
import { MegaFilterPaginationComponent } from './megafilter-pagination/megafilter-pagination.component';
import { PdfOverlayComponent } from './pdf-overlay/pdf-overlay.component';
import { FirmarPdfComponent } from './firmar-pdf/firmar-pdf.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { WizardStepComponent } from './wizard-step/wizard-step.component';
import { FirmarPdfMasivoComponent } from './firmar-pdf-masivo/firmar-pdf-masivo.component';
import { CountdownComponent } from './countdown/countdown.component';
import { FirmaFacialComponent } from './firma-facial/firma-facial.component';
import { WebcamModule } from 'ngx-webcam';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { PickerCalendarComponent } from './picker-calendar/picker-calendar.component';
import { TerminosYCondicionesComponent } from './terminos-y-condiciones/terminos-y-condiciones.component';
import { DotNewComponent } from './dot-new/dot-new.component';
import { CustomColorDirective } from '../directives/custom-color.directive';
import { MaterialModule } from './material.module';
import { AlertComponent } from './alert/alert.component';
import { PlaceholderDirective } from '../directives/placeholder/placeholder.directive';
import { CapitalizeNamePipe } from '../pipes/capitalize-name.pipe';
import { BoxAreaComponent } from './box-area/box-area.component';
import { LivenessDetectionComponent } from './liveness-detection/liveness-detection.component';
import { CapitalizeFirstPipe } from '@noe/pipes/capitalize-first.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { InputPasswordDirective } from '@noe/directives/input-password/input-password.directive'
import { ChartsModule } from 'ng2-charts';
import { MenuDropdownDirective } from '@noe/directives/menu-dropdown/menu-dropdown.directive';
import { SearchComponent } from './search/search.component';
import { TextValueAccessorDirective } from '@noe/directives/control-value-accessor/text-value-accessor.directive';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { TableScrollComponent } from './table-scroll/table-scroll.component';
import { ModalInicioComponent } from './modal-inicio/modal-inicio.component';
import { ModalLecturaComponent } from './modal-lectura/modal-lectura.component';
import { FirmarPdfNuevoComponent } from './firmar-pdf-nuevo/firmar-pdf-nuevo.component';
import { S3ImagePreviewComponent } from './s3-image-preview/s3-image-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { LoaderScrollComponent } from './loader-scroll/loader-scroll.component';
import { NgxCopilotModule } from 'ngx-copilot';
import { ModalFelicitacionesComponent } from './modal-felicitaciones/modal-felicitaciones.component';
import { ModalVisorComponent } from './modal-visor/modal-visor.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoaderScrollPreviewComponent } from './loader-scroll-preview/loader-scroll-preview.component';
import { LoaderFirmarComponent } from './loader-firmar/loader-firmar.component';
import { DropzoneSubirComponent } from './dropzone-subir/dropzone-subir.component';
import { ModalDocumentosComponent } from './modal-documentos/modal-documentos.component';
import { S3ImageMainComponent } from './s3-image-main/s3-image-main.component';
import { LoaderFullScreenComponent } from './loader-full-screen/loader-full-screen.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoaderImgComponent } from './loader-img/loader-img.component';
import { S3ImageBloqueoComponent } from './s3-image-bloqueo/s3-image-bloqueo.component';
import { TablePaginationScrollComponent } from './table-pagination-scroll/table-pagination-scroll.component';
import { TableSinScrollComponent } from './table-sin-scroll/table-sin-scroll.component';
import { TablePaginationSinScrollComponent } from './table-pagination-sin-scroll/table-pagination-sin-scroll.component';
import { TableFirmaSinScrollComponent } from './table-firma-sin-scroll/table-firma-sin-scroll.component';
import { TableFirmaModalSinScrollComponent } from './table-firma-modal-sin-scroll/table-firma-modal-sin-scroll.component';
import { TableUsuariosSinScrollComponent } from './table-usuarios-sin-scroll/table-usuarios-sin-scroll.component';
import { TableFirmaSinScroll1Component } from './table-firma-sin-scroll-1/table-firma-sin-scroll-1.component';
import { InputMitsuiComponent } from './input-mitsui/input-mitsui.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TablePaginationNotificacionesSinScrollComponent } from './table-pagination-notificaciones-sin-scroll/table-pagination-notificaciones-sin-scroll.component';

@NgModule({
  declarations: [
    InputComponent, 
    InputMitsuiComponent,
    DatepickerComponent, 
    AutocompleteComponent,
    SearchComponent, 
    MultiselectComponent, 
    ModalComponent, 
    ModalInicioComponent, 
    ModalLecturaComponent,
    ModalFelicitacionesComponent,
    ModalDocumentosComponent,
    ModalVisorComponent,
    AlertComponent,
    LoaderComponent, 
    LoaderImgComponent,
    LoaderFullScreenComponent,
    LoaderFirmarComponent,
    LoaderScrollComponent,
    LoaderScrollPreviewComponent,
    TableComponent,
    TableUsuariosSinScrollComponent,
    TablePaginationNotificacionesSinScrollComponent,
    TableSinScrollComponent,
    TablePaginationSinScrollComponent,
    TableFirmaSinScrollComponent,
    TableFirmaSinScroll1Component,
    TableFirmaModalSinScrollComponent,
    TableScrollComponent,
    TablePaginationComponent,
    TablePaginationScrollComponent,
    DropzoneComponent, 
    DropzoneSubirComponent,
    NotificationsComponent, 
    WizardComponent, 
    PasswordValidatorsComponent, 
    S3ImageComponent, 
    S3ImageBloqueoComponent,
    S3ImageMainComponent,
    SwitchComponent, 
    MegaFilterComponent,
    MegaFilterPaginationComponent,
    PdfOverlayComponent,
    FirmarPdfComponent,
    WizardStepComponent,
    FirmarPdfMasivoComponent,
    CountdownComponent,
    FirmaFacialComponent,
    DaterangepickerComponent,
    PickerCalendarComponent,
    TerminosYCondicionesComponent,
    DotNewComponent,
    BoxAreaComponent,
    LivenessDetectionComponent,
    TooltipComponent,
    CustomColorDirective,
    PlaceholderDirective,
    InputPasswordDirective,
    MenuDropdownDirective,
    TextValueAccessorDirective,
    CapitalizeNamePipe,
    CapitalizeFirstPipe,
    S3ImagePreviewComponent,
    FirmarPdfNuevoComponent,
    CapitalizeFirstPipe
    //TabGroupCustomComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularSignaturePadModule,
    WebcamModule,
    MaterialModule,
    ChartsModule,
    ScrollingModule,
    MatTabsModule,
    MatTooltipModule,
    NgxCopilotModule,
    InfiniteScrollModule,
    NgOtpInputModule,
    DragDropModule
  ],
  exports: [
    InputComponent,
    InputMitsuiComponent, 
    DatepickerComponent, 
    AutocompleteComponent,
    SearchComponent,
    MultiselectComponent,
    ModalComponent,
    ModalInicioComponent, 
    ModalLecturaComponent, 
    ModalFelicitacionesComponent,
    ModalDocumentosComponent,
    ModalVisorComponent,
    AlertComponent,
    LoaderComponent,
    LoaderImgComponent,
    LoaderFullScreenComponent,
    LoaderFirmarComponent,
    LoaderScrollComponent,
    LoaderScrollPreviewComponent,
    TableComponent,
    TableUsuariosSinScrollComponent,
    TablePaginationNotificacionesSinScrollComponent,
    TableSinScrollComponent,
    TablePaginationSinScrollComponent,
    TableFirmaSinScrollComponent,
    TableFirmaSinScroll1Component,
    TableFirmaModalSinScrollComponent,
    TableScrollComponent,
    TablePaginationComponent,
    TablePaginationScrollComponent,
    DropzoneComponent,
    DropzoneSubirComponent,
    NotificationsComponent,
    WizardComponent,
    PasswordValidatorsComponent,
    S3ImageComponent,
    S3ImageBloqueoComponent,
    S3ImageMainComponent,
    SwitchComponent,
    MegaFilterComponent,
    MegaFilterPaginationComponent,
    PdfOverlayComponent,
    FirmarPdfComponent,
    WizardStepComponent,
    CountdownComponent,
    FirmaFacialComponent,
    DaterangepickerComponent,
    TerminosYCondicionesComponent,
    DotNewComponent,
    BoxAreaComponent,
    LivenessDetectionComponent,
    TooltipComponent,
    CustomColorDirective,
    PlaceholderDirective,
    InputPasswordDirective,
    MenuDropdownDirective,
    TextValueAccessorDirective,
    CapitalizeNamePipe,
    CapitalizeFirstPipe,
    MaterialModule,
    ChartsModule,
    S3ImagePreviewComponent,
    FirmarPdfNuevoComponent,
    ScrollingModule,
    NgxCopilotModule,
    InfiniteScrollModule,
    NgOtpInputModule,
    DragDropModule
  ]
})
export class SharedModule { }
