import { Component, OnInit,Output, EventEmitter, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';

import { takeUntil } from 'rxjs/operators';

import { Opcion } from 'src/app/models/Opcion.model';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { OpcionesService } from 'src/app/services/opciones.service';
import { TableTd } from 'src/app/models/Table.model';
import { ServiciosProcesoService } from 'src/app/services/servicios-proceso.service';
import { SeguimientoService } from 'src/app/services/seguimiento.service';
import { DashboardService } from 'src/app/services/dasboard.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { PermisosService } from 'src/app/services/permisos.service';
import { BaseUnsubscribe } from 'src/app/util/base-unsubscribe';
import { PersonalizacionService } from 'src/app/services/personalizacion.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { SideBarService } from 'src/app/services/sidebar.service';
import { ReceptorService } from 'src/app/services/receptor.service';
import { TipoFirma } from '@noe/models/TipoFirma.model';
import { Usuario } from '@noe/models/Usuario.model';

import { Auth } from 'aws-amplify'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { PasswordValidatorsService } from 'src/app/services/password-validators.service';
import { CompartirDatosService } from '@noe/services/compartirDatos.service';
import { EmpresaService } from '@noe/services/empresa.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseUnsubscribe implements OnInit {

  @Output() public tienePendientes = new EventEmitter<number>();
  @Output()
  onnavigation: EventEmitter<any> = new EventEmitter()

  @ViewChild('modalAviso') modalAviso:ModalComponent
  @ViewChild('modalObtenerPremium') modalObtenerPremium:ModalComponent
  @ViewChild('modalInicioTerminos') modalInicioTerminos:ModalComponent
  @ViewChild('modalLecturaTerminos') modalLecturaTerminos:ModalComponent
  @ViewChild('modalCaducidadConstrasena') modalCaducidadConstrasena:ModalComponent
  @Output() 
  onconfirm: EventEmitter<any> = new EventEmitter()
  @Output() 
  confirmacionFirma: EventEmitter<any> = new EventEmitter()
  passForm:FormGroup

  constructor(
    private authService: AuthService,
    public opcionesService: OpcionesService,
    public notify: NotificationsService,
    public router: Router,
    private service: ServiciosProcesoService,
    
    public serviceSeguimiento: SeguimientoService,
    public dashboardService:DashboardService,
    public usuarioService: UsuariosService,
    public permisosService: PermisosService,
    private customService: PersonalizacionService,
    private sidebarService: SideBarService,
    public receptorService: ReceptorService,
    public fv: FormValidationService,
    public validators: PasswordValidatorsService,
    public fb: FormBuilder,
    public compartirDatosService: CompartirDatosService,
    public empresaService: EmpresaService,
  ) {
    super();
  }

  private readonly defaultLogo = '../../../assets/img/logo-1.png';

  rol:string
  esGratis:boolean
  opcionesUsuario:string[] = []
  isAdminComerciales:boolean;
  personalizar:boolean
  body:TableTd[][] = []
  hayPendientes:boolean; 
  hayEnProceso:boolean;
  isThereToNotify: boolean;
  opciones:Opcion[] = []
  hayDocumentosReceptor:boolean;
  //logoSidebar: string = this.defaultLogo;
  logoSidebar: string
  activeMetadataProcess: boolean;
  perfiles:string[] = []
  isAdministradorProfile:boolean
  tipoFirmas:TipoFirma
  noHayTiposDeFirma:boolean;
  codeOption:string[]=[];
  codeOptionChild:boolean
  activeFlagPapelera: boolean = false;
  activeFlagSession: boolean;
  activateExtraFieldsToTables: boolean = false; //flag ADEX
  activeFlagOffices: boolean = false; //ADEX
  flagTerms: boolean = false; //flag ADEX
  selectedMessage:boolean;
  subdominio:string;
  start:boolean = false
  idEmpresaActiva:any
  navbarOpen: boolean = true;
  navbarOpenResp: boolean = false;
  flagSubdomainMasa:boolean = false;
  flagAdex:boolean = false;

  ngOnInit(): void {    
    console.log("SIDEBAR")
    this.start = localStorage.getItem('AUTHSTART') === 'start'
    console.log(this.start)
    if (this.start) {
      this.obteniendoDatos(localStorage.getItem('AUTHUSER'), localStorage.getItem('AUTHSUBDOMINIO'))
    }else{
      this.idEmpresaActiva = this.authService.obtenerIdEmpresaActiva();
      this.cargarEmpresaActiva();
    }
  }

  toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
      console.log('Abrir Barra de Opciones Lateral');
  }
  
  cargarEmpresaActiva(){
    this.recibirMetodo()
    this.usuarioService.obtenerUsuario( this.authService.obtenerUsername(), this.authService.obtenerSubdominio()).subscribe(data => {
      console.log("SIDEBAR 2", data)
      this.listarOpciones();

      this.personalizar = this.authService.obtenerPermisoPersonalizacion() === 'true'
      this.esGratis = this.authService.obtenerTipoCuenta() === 'GRATUITA';
      this.activeMetadataProcess = this.authService.getActiveMetadataProcess() === 1; 
      this.perfiles = this.authService.obtenerPerfiles()
     
      this.isAdministradorProfile =this.perfiles.includes("ADMINISTRADOR")
      this.isAdminComerciales = this.authService.obtenerRolesArreglo().includes(5)
      this.rol = this.authService.obtenerRoles()
      this.listenCustomImages();

      this.authService.generarPermisos(data.profiles)
      this.opcionesUsuario = this.authService.obtenerOpciones()

    //Si la empresa cuenta con el perfil de papelera
    this.codeOption = this.authService.obtenerOpciones();
    this.codeOptionChild=this.codeOption.includes('0000209');

    //flag ADEX
    this.activateExtraFieldsToTables = this.authService.getActivateExtraFieldsToTables() === 1;
    this.flagTerms = this.authService.obtenerFlagTerminosyCondiciones() === 1;
    this.activeFlagPapelera = this.authService.getactiveFlagPapelera() === 1;
    this.activeFlagOffices = this.authService.getActiveFlagOffices() === 1;
    this.flagAdex = this.authService.getStringReasonSocial() === 'ASOCIACION DE EXPORTADORES' || 
    this.authService.getStringReasonSocial() === 'INSTITUTO DE EDUCACION SUPERIOR PRIVADO DE COMERCIO EXTERIOR';

    //flag MITSUI
    this.logoSidebar = this.defaultLogo;
    this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;



    this.activeFlagSession = data.flagSession;  
    console.log(data, this.activeFlagSession, 'USUARIO')

      //si ES GRATIS
      //this.opcionesUsuario = this.opcionesCuentaFree

      this.calcularTotalPendientes()
      this.calcularTotalEnProceso();
      this.cantidadesDashboard()
     
      if (data.flagSession === 1) {
        this.handleAcceptInicio();
        console.log(this.activeFlagSession, 'SESSION INICIO');
      } else {
        this.handleAcceptLectura();
        console.log(this.activeFlagSession, 'SESSION LECTURA');
      }
      
      if  (this.rol=='receptor') {
        this.calcularTotalDocumentosReceptor()
      }

      this.noHayTiposDeFirma = data.desc_tipos_firmas.length > 0 ?true:false;
  
      //this.obtenerReceptor();
      this.validarCaducidadContrasena();
      
    }, error => {
      console.log(error)
    }); 
  }

  obteniendoDatos(user, subdomain){
    this.recibirMetodo()
    this.usuarioService.obtenerUsuario(user, subdomain).subscribe(data => {
      console.log("SIDEBAR 1",data)
      this.idEmpresaActiva = data.companys[0].id
      this.listarOpciones()

      this.authService.guardarDatos(data)
      this.permisosService.setOpciones()

      this.authService.generarPermisos(data.profiles)
      this.opcionesUsuario = this.authService.obtenerOpciones()

      //Si la empresa cuenta con el perfil de papelera
      this.codeOption = this.authService.obtenerOpciones();
      this.codeOptionChild=this.codeOption.includes('0000209');

      this.activeFlagSession = data.flagSession;  
      //console.log(data, this.activeFlagSession, 'USUARIO')

      //si ES GRATIS
      //this.opcionesUsuario = this.opcionesCuentaFree

      this.calcularTotalPendientes()
      this.calcularTotalEnProceso();
      this.cantidadesDashboard()
     
      if (data.flagSession === 1) {
        this.handleAcceptInicio();
        console.log(this.activeFlagSession, 'SESSION INICIO');
      } else {
        this.handleAcceptLectura();
        console.log(this.activeFlagSession, 'SESSION LECTURA');
      }
      
      if  (this.rol=='receptor') {
        this.calcularTotalDocumentosReceptor()
      }

      this.noHayTiposDeFirma = data.desc_tipos_firmas.length > 0 ?true:false;
  
      //this.obtenerReceptor();
      this.validarCaducidadContrasena();

      this.empresaService.obtenerEmpresa(this.authService.obtenerIdEmpresaActiva()).subscribe(dataEmp => {
        console.log("SIDEBAR 2",dataEmp)
        this.authService.guardarDataEmpresaActiva(dataEmp)
        this.personalizar = this.authService.obtenerPermisoPersonalizacion() === 'true'
        this.esGratis = this.authService.obtenerTipoCuenta() === 'GRATUITA';
        this.activeMetadataProcess = this.authService.getActiveMetadataProcess() === 1; 
        this.perfiles = this.authService.obtenerPerfiles()
       
        this.isAdministradorProfile =this.perfiles.includes("ADMINISTRADOR")
        this.isAdminComerciales = this.authService.obtenerRolesArreglo().includes(5)
        this.rol = this.authService.obtenerRoles()
  
        //flag ADEX
        this.activateExtraFieldsToTables = this.authService.getActivateExtraFieldsToTables() === 1;
        this.flagTerms = this.authService.obtenerFlagTerminosyCondiciones() === 1;
        this.activeFlagPapelera = this.authService.getactiveFlagPapelera() === 1;
        this.activeFlagOffices = this.authService.getActiveFlagOffices() === 1;
        this.flagAdex = this.authService.getStringReasonSocial() === 'ASOCIACION DE EXPORTADORES' || 
        this.authService.getStringReasonSocial() === 'INSTITUTO DE EDUCACION SUPERIOR PRIVADO DE COMERCIO EXTERIOR';

        //flag MITSUI
        this.logoSidebar = this.defaultLogo;
        this.flagSubdomainMasa = this.authService.getflagSubdomainMasa() === 1;
        
        this.listenCustomImages();
      }, error => {
        console.log(error)
      })

    }, error => {
      console.log(error)
    })
 
  }

  accept:boolean
  handleAcceptInicio() {
      console.log("TyC : ", this.flagTerms)
      if(this.activateExtraFieldsToTables && this.flagTerms){
        //Solucion Temporal para topbar que se muestra en terminos
        const topbar = document.getElementsByTagName('app-topbar')[0];
        topbar.classList.add('topbar-temporal-oculto');
        //temporal
        if (this.accept) {
          this.accept = false;
        } else {
            this.modalInicioTerminos.open();
        }
      }
  }

  handleAcceptLectura() {
      this.modalLecturaTerminos.open();
  }

  recibirMetodo() {
    this.compartirDatosService.currentMessage
    .subscribe(message => (
      this.selectedMessage = message
      ));
      return this.selectedMessage
  }

  handleAcceptConfirm() {
      this.onconfirm.emit('ENVIAR VALOR')
      console.log(this.accept,'ACCEPT')

      const usuario:Usuario = {
        flagSession: 1
      }

      //update flagTerms in user
      if(this.accept){
        this.receptorService.modificarTermsUsuario(this.authService.obtenerUsername(),this.authService.obtenerSubdominio(), "0").subscribe (
          data => {
            this.authService.guardarFlagTerminosyCondiciones("0");
            console.log('FLAG TERMS DESHABILITADO')
            //Solucion Temporal para topbar que se muestra en terminos
            const topbar = document.getElementsByTagName('app-topbar')[0];
            topbar.classList.remove('topbar-temporal-oculto');
            //temporal
          }
        );
      }
      //this.usuarioService.actualizarUsuario(this.authService.obtenerUsername(), this.authService.obtenerSubdominio(), usuario).subscribe(data => {
      this.usuarioService.obtenerUsuario( this.authService.obtenerUsername(), this.authService.obtenerSubdominio()).subscribe(data => {
        data.flagSession = 1
        console.log(data, 'SESSION CONFIRM') 
      }, error => {
        console.log(error)
      }); 
  }

  listenCustomImages(): void {
    this.customService.listarImagenesPersonalizacionLogeado(
      this.authService.obtenerSubdominio() , this.authService.obtenerIdEmpresaActiva()
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe((response: any) => {
      const { logoWeb } = response;

      this.logoSidebar = logoWeb
        ? logoWeb
        : this.defaultLogo;
    });
  }

  changeSignDocumentsPosition(optionList: Opcion[]): any[] {
    const signaturePanel = optionList.find((option: Opcion) => {
      return option.code_child === '0000002';
    });

    const signDocumentsOpt = signaturePanel.list_options.find((option: Opcion) => {
      return option.code_child === '0000205';
    });

    return optionList.map((option: Opcion) => {
      if (option.code_child === '0000002') {
        const newListOptions = option.list_options.filter((option: Opcion) => {
          return option.code_child !== '0000205';
        });

        return {
          ...option,
          list_options: [signDocumentsOpt, ...newListOptions]
        };
      }
      return option;
    });
  }
  
  listarOpciones() {
    this.opcionesService.listarOpciones().subscribe(data => {

      if (this.activeMetadataProcess) {
        data[2].list_options.push({
          action: 'reportes-poderosa',
          code_child: '0000304',
          code_dad: '0000003',
          enabled: true,
          level: 1,
          name: 'reportes poderosa',
          option_free: 0,
        });
      }

      /*data[1].list_options.push({
        action: 'bandeja-gestion-contratos',
        // code_child: '0000201',
        code_dad: '0000002',
        enabled: true,
        level: 1,
        name: 'bandeja de gestión de contratos',
        option_free: 0,
      });*/
      const newOptions = this.changeSignDocumentsPosition(data);

      const opciones: Opcion[] = [...newOptions];

      if(!this.isAdministradorProfile && this.esGratis){
        opciones.splice(0,1);
        opciones.pop();
      }else if(this.esGratis){
        opciones.pop();
      }
     
      for (const modulo of  opciones) {
        if (this.opcionesUsuario.includes(modulo.code_child)) {
          modulo.action = modulo.name.replace(/ /g, "-")
          modulo.enabled = true
          for (const hijo of modulo.list_options) {

            if (this.opcionesUsuario.includes(hijo.code_child)) {
              hijo.action = hijo.name.replace(/ /g, "-")
             hijo.enabled = true
            }
          }
        }
      }

      this.opciones = [...opciones];
    }, error => {
      this.notify.error('Hubo un problema al obtener las opciones')
    })
  }

  porNotificar:boolean
  cantidadesDashboard() {
    this.dashboardService.cantidadesDocumentosRealizados(
      this.idEmpresaActiva,
      this.authService.obtenerUsername(),
      this.authService.obtenerSubdominio()).subscribe((data:any) => {
     
      this.porNotificar =data.documentsToNotify != 0 ?true:false

    }, error => {
      console.log(error)
    })
  }

  cerrarModalObtenerPremium(){
    this.modalObtenerPremium.close()

  }


  modalCambiarmeCuentaPagoLoading:boolean

  confirmarObtenerPremium(){
    this.modalCambiarmeCuentaPagoLoading = true
    const data:any={
      idCompany:this.authService.obtenerIdEmpresaActiva(),
      user:this.authService.obtenerUsername(),
      subdomain:this.authService.obtenerSubdominio()
    }
    this.service.enviarCorreoContactarme(data).subscribe(data => {
      this.notify.success("Se envió la solicitud correctamente.")
      this.modalCambiarmeCuentaPagoLoading = false
      this.modalObtenerPremium.close()
      //this.modalContactarCuentaFreeLoading = false
    }, error => {
      this.notify.error(error.error.response)
      //this.modalContactarCuentaFreeLoading = false
    })

  }

  calcularTotalPendientes(): void {
   
    this.dashboardService.listarPendientes(
      this.authService.obtenerIdEmpresaActiva(),
      this.authService.obtenerUsername(),
      this.authService.obtenerSubdominio()).subscribe((data:any) => {
     
      this.hayPendientes = data.documentsPending > 0 ?true:false; 
    
    }, error => {
      console.log(error)
    })
  }

  calcularTotalDocumentosReceptor(){
    //this.loading = true
    this.dashboardService.listarDocumentosReceptor(
      this.authService.obtenerIdEmpresaActiva(),
      this.authService.obtenerUsername(),
      this.authService.obtenerSubdominio()
    ).subscribe((data:any) => {
  
      this.hayDocumentosReceptor = data.documents > 0 ?true:false;
    }, error => {
     
      console.log(error)
    })
  }

  calcularTotalEnProceso(){

    //this.loading = true
    this.dashboardService.listarProcesos(
      this.authService.obtenerSubdominio(),
      this.authService.obtenerUsername(),
      this.authService.obtenerIdEmpresaActiva()
    ).subscribe((data:any) => {

      this.hayEnProceso = data.documentsProcess > 0 ?true:false; 
     
    }, error => {
     
      console.log(error)
    })
  }

  irDashboard(){
    this.router.navigate(['/'])
  }

  convertirCuentaPago(){
  }

  alertaAviso(modulo: string, hijo: string){


    console.log("ES PERSONALIZAR")

    this.sidebarService.showWarning$.next({
      modulo,
      hijo,
      isCustom: this.personalizar
    });
  }

  abrirModalPremium(){
    this.modalObtenerPremium.open()
  }

  // Caducidad de Contrasena --inicio

  
  validarCaducidadContrasena() {
    this.receptorService.validarCaducidadContraseña(this.authService.obtenerUsername(),this.authService.obtenerSubdominio()).subscribe( data => {
      if(data == 'NONE'){
        console.log('SIN FECHA CONTRASEÑA')
      }else if(data == 'FALSE'){
        console.log('SIN CADUCAR')
      }else if(data == 'TRUE'){
        if (!this.passForm) {
          const passwordValidators = this.validators.set()
          this.passForm = this.fb.group({
            oldPass: ['', [Validators.required, Validators.minLength(6)]],
            password: ['', passwordValidators],
            confirm: ['', [Validators.required]]
          }, { validators: this.checkPasswords })
        }
        //Cambiando z-index a sidebar para evitar superposicion de elementos
        const sidebar = document.getElementsByClassName('sidebar')[0];
        sidebar.classList.add('sidebar-frente');
        this.modalCaducidadConstrasena.open()
        console.log('CADUCADO')
      }

    })
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirm').value;
    return pass === confirmPass ? null : { mismatch: true }    
  }
  pLoading:boolean
  async changePassword(e) {
    e.preventDefault()
    if (this.pLoading) return
    if (this.passForm.invalid) {
      return this.fv.validateForm(this.passForm)
    }
    this.pLoading = true
    try {
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.changePassword(user, this.passForm.get('oldPass').value, this.passForm.get('password').value);
      if (result === 'SUCCESS') {
        try{
          this.receptorService.actualizarFechaContraseña(this.authService.obtenerUsername(), this.authService.obtenerSubdominio()).subscribe(
            data => {
              console.log('Fecha Contraseña Actualizada')
          })
          this.pLoading = false
          this.modalCaducidadConstrasena.close()
          this.passForm.reset()
          this.notify.success('Contraseña cambiada con éxito')

          //Cambiando z-index a sidebar para evitar superposicion de elementos
          const sidebar = document.getElementsByClassName('sidebar')[0];
          sidebar.classList.remove('sidebar-frente');
          
        }catch(error){
          console.log('Hubo un problema al actualizar la fecha')
        }
      } else {
        this.notify.error('Hubo un problema al procesar la solicitud')
      }
    } catch (error) {
      this.notify.error('Hubo un problema al realizar la petición')
    }
    
    this.pLoading = false
  }
  // Caducidad de Contrasena --fin

}
