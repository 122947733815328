import { 
  Component,
  Input, 
  HostListener, 
  ViewChildren, 
  QueryList, 
  ElementRef, 
  Output, 
  EventEmitter
} from '@angular/core';
import { formatDate } from '@angular/common';
import { Filter } from 'src/app/models/Filter.model';
import { ProcesoService } from 'src/app/services/proceso.service';
import { DocumentoService } from 'src/app/services/documento.service';
import { OficinaService } from 'src/app/services/oficina.service';
import { ReceptorService } from 'src/app/services/receptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Proceso } from 'src/app/models/Proceso.model';
import { IField } from '@noe/models/Field.model';

@Component({
  selector: 'app-megafilter-pagination',
  templateUrl: './megafilter-pagination.component.html',
  styleUrls: ['./megafilter-pagination.component.scss']
})
export class MegaFilterPaginationComponent {

  @Input() placeholder: string;
  @Input() filters: Filter[] = [];
  @Input() session: string;

  @Output() onset = new EventEmitter<any>();
  @Output() remaingFilters = new EventEmitter<Filter[]>();

  @ViewChildren('list') el: QueryList<ElementRef>;

  @HostListener('document:click', ['event']) clickout(event) {
    if (this.openFilter) {
      this.openFilter = false;
    }
    this.type = null;
  }

  constructor(
    public procesoService: ProcesoService,
    public documentoService: DocumentoService,
    public oficinaservice: OficinaService,
    public receptorService: ReceptorService,
    public authService: AuthService,
    private fb:FormBuilder,
  ) { }
  //formMultiselect:FormGroup
  pasoUno:FormGroup
  destroy$ = new Subject();

  

  ngOnInit(): void {
    this.listarProcesos()
    this.listarDocumentos()
    this.listarOficinas()
    this.listarProcesosCompartidos()
    //this.listenIdProcessType()
    this.initForm()
  }

  openFilter:boolean
  searchTerm:string
  type:string

  theValue:string;
  theValueMultiselect:string;
  theDate:Date = new Date();

  editing:number = -1;
  selectedfilterList = [];

  
  applyFilters(e,string) {
    this.session = string;
    if(e > 1){
      if (sessionStorage.getItem(this.session)) {
      
        var filters2 = JSON.parse(sessionStorage.getItem(this.session))
  
        for( var i=0 ; i < filters2.length -1 ; i++){
          if(filters2[i].on != this.filters[i].on){
            filters2[i].on = !filters2[i].on
            this.onset.emit({text: '', prop: filters2[i].prop, col: filters2[i].col});//Solucion Temporal --pv
            sessionStorage.setItem(string, JSON.stringify(filters2))
          }
        }
        this.filters = JSON.parse(sessionStorage.getItem(this.session))
      }
    }else{
      var filSession = sessionStorage.getItem(this.session)
      this.filters = filSession != null && filSession != undefined ? JSON.parse(sessionStorage.getItem(this.session)) : this.filters;
      sessionStorage.setItem(string, JSON.stringify(this.filters))
    }
  }

  initForm(): void {
    this.pasoUno = this.fb.group({
      idProcessType: ['', [Validators.required]]
    })
  }

  checkFiltersOn() {
    return this.filters.every( filter => filter.on );
    
  }
  checkFiltersOff() {
    return this.filters.every( filter => !filter.on );
  }

  openMegaFilter(event) {
    event.stopPropagation();
    this.openFilter = true;
    if (this.type) {
      this.type = null;
      this.editing = -1;
    }
  } 

  selectType(event, type, i) {
    event.stopPropagation();
    this.type = type;
    this.activeFilter = i;
    this.openFilter = false;
  }

  cancelChange() {
    this.filters[this.activeFilter].value = '';
    this.filters[this.activeFilter].on = true;
  }

  setFilter(event) {    
    console.log("pintame al inicioooooo",event)  
    if (event != '') {

      if (!this.type) {
        return false;
      }

      let value = '';
      let tituloOficina = '';
      let tituloOficinasGeneral = '';
      let idProceso = ''

      if (this.type == 'date' || this.type == 'earlier-than' || this.type == 'later-than' ||this.type == 'multiSelectProcesoss') {
        value = formatDate(event, 'dd/MM/yyyy', 'en_US')
      } else if (this.type == 'select') {
        value = event.target.value
        console.log("puchin value:",value)
      } 
      else if (this.type == 'selectProcesos') {
        value = event.target.value
        console.log("pintame value:",value)
      } 
      else if (this.type == 'selectDocumentos') {
        value = event.target.value
      }
      else if(this.type == 'selectOficinas'){
        value = event.target.options[event.target.selectedIndex].text
        //console.log('value2',event.target.options[event.target.selectedIndex].text)
        tituloOficina = event.target.value
      }else if (this.type == 'multiSelectProcesos') {
        value = event[0].name
        idProceso = event[0].id
        console.log("pintame value name proceso:",value)
        console.log("pintame value id proceso:",idProceso)
      }
      else if (this.type == 'selectProcesosCompartidos') {
        value = event.target.value
      }
      else if (this.type == 'multiSelectProcesos') {
        value = event[0].name
        idProceso = event[0].id
        console.log("pintame value name proceso:",value)
        console.log("pintame value id proceso:",idProceso)
      }
      else if (this.type == 'selectProcesosCompartidos') {
        value = event.target.value
      }
      else {
        value = event;
      }

      console.log("pintame editing:",this.editing)
      if (this.editing != -1) {
        this.filters[this.editing].value = value;
        this.editing = -1;
       
      } else {
        this.filters[this.activeFilter].value = value;
        this.filters[this.activeFilter].on = false;
      }


      let filter:any = {
        text: this.filters[this.activeFilter].value,
        prop: this.filters[this.activeFilter].prop, 
        col: this.filters[this.activeFilter].col,
        exact: this.filters[this.activeFilter].exact,
        epoch: undefined,
        id:undefined
      }

      if(this.type == 'selectOficinas'){
        filter.text = tituloOficina;
      }
      if(this.type == 'selectOficinasGeneral'){
        filter.text = tituloOficinasGeneral;
      }

      if(this.type == 'multiSelectProcesos'){
        filter.id = idProceso
      }

      sessionStorage.setItem(this.session, JSON.stringify(this.filters))

      if (this.type == 'date') {
        filter.date = event
      }
      if (this.type == 'earlier-than') {
        filter.type = this.type
        event.setUTCSeconds(59)
        event.setUTCMinutes(59)
        event.setUTCHours(23)
        filter.epoch = Date.parse(event)
      }
      if (this.type == 'later-than') {
        filter.type = this.type
        event.setUTCSeconds(0);
        event.setUTCMinutes(0);
        event.setUTCHours(0);
        filter.epoch = Date.parse(event);
      }
      this.onset.emit(filter);
      this.selectedfilterList.push(filter);

      this.type = null;
      this.theValue = '';
    }
    
  }

  rmFilter(event, i, col, type) {
    event.stopPropagation();
    this.filters[i].value = '';
    this.filters[i].on = true;
    this.selectedfilterList = this.selectedfilterList.filter((el) => {
      if (['earlier-than', 'later-than'].includes(type)) {
        return el.type !== type;
      }
      return el.col !== col;
    });

    this.onset.emit({
      text: '',
      prop: col,
      col,
      remainFilters: this.selectedfilterList
    });

    sessionStorage.setItem(this.session, JSON.stringify(this.filters))
  }
  
  editFilter(event, i, type, value) {
    this.openFilter = false
    event.stopPropagation();
    this.editing = i;
    this.type = type;
    this.theValue = value;
  }

  activeFilter: number;
  keyControls(event) {
    
    if (event.keyCode == 38) {
      // UP
      this.setActive(1, -1)
    } else if (event.keyCode == 40) {
      // DOWN
      this.setActive(1, +1)
    } 
    
    
    if (event.keyCode == 13) {
      event.stopPropagation();
      event.preventDefault();
      this.selectType(event, this.filters[this.activeFilter].type, this.activeFilter);
    }
    
  }

  setActive(i, dir) {
    const check = this.activeFilter + dir;

    event.stopPropagation();
    event.preventDefault();

    if ( check >= 0 && check < this.filters.length ) {
      this.activeFilter = this.activeFilter + dir;
      this.el.toArray()[check].nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }

  clearFilters(e = null) {
    if (e) e.stopPropagation()
    Object.keys(this.filters).forEach(o => {
      //console.log(o)
      this.filters[o].value = '';
      this.filters[o].on = true;
    });
    
    sessionStorage.setItem(this.session, JSON.stringify(this.filters))
    
    this.onset.emit({text: '', prop: this.filters[1].prop, col: this.filters[1].col});//Limpiar y enviar un emit --pv
    this.selectedfilterList = [];
  }

  clearInputs() {
    this.openFilter = false;
  }

  dataProcesos?:any=[]
  listarProcesos() {
    this.procesoService.listarProcesosActivos(this.authService.obtenerIdEmpresaActiva()).subscribe(data => {
      this.dataProcesos = data
      console.log("pintameee xddd",this.dataProcesos)
      this.dataProcesos = this.ordenarAlfabeticamente(this.dataProcesos)
    }, error => {console.log(error)})
  }

  dataDocumentos?:any=[]
  listarDocumentos() {
    this.documentoService.listarDocumentosActivos(this.authService.obtenerIdEmpresaActiva()).subscribe(data => {
      this.dataDocumentos = data
      this.dataDocumentos = this.ordenarAlfabeticamente(this.dataDocumentos)
    }, error => {console.log(error)})
  }

  procesos:Proceso[] = []
  listarProcesosCompartidos() {
    console.log("entrasss...!!!")
    this.procesoService.listarProcesosCompartidosActivos(this.authService.obtenerSubdominio()).subscribe((data: any[]) => {
      this.procesos = data
      console.log("pintameee dataa", this.procesos)
      this.processFieldList = data.filter((process) => {
        return process.attributes && process.attributes.fields;
      });
      console.log("pintameee processFieldList", this.processFieldList)
    }, error => { console.log(error) })
  }

  dataOficina?:any=[]
  listarOficinas(){
    this.oficinaservice.listarOficinas(this.authService.obtenerIdEmpresaActiva()).subscribe(data =>{
      this.dataOficina = data
        this.dataOficina = this.ordenarAlfabeticamente(this.dataOficina)
    }, error => {
      console.log(error)
    })
    /*this.receptorService.listarOficinasPorUsuario(this.authService.obtenerIdEmpresaActiva(),this.authService.obtenerSubdominio(),this.authService.obtenerUsername()).subscribe( data => {
      this.dataOficina = data
      this.dataOficina = this.ordenarAlfabeticamente(this.dataOficina)
    }, error => {
      console.log(error)
    })*/
    console.log('Listado de grupo interno usuario :', this.dataOficina)
  }

  ordenarAlfabeticamente(lista : any[]) : any[]{
    //ordenar alfabeticamente
    lista.sort( function(a,b) {
      if(a.name > b.name){
        return 1;
      }
      if(a.name < b.name){
        return -1;
      }
      return 0;
    })
    return lista
  }

  

  processFieldList: Proceso[] = [];
  listenIdProcessType(): void {
    this.pasoUno.get('idProcessType').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((idValue: string) => {
          console.log("como viene:","idValue")
          if( idValue.length>0){
            idValue = idValue[0]['id']
            console.log("valor [0]:",idValue)
          }else{
            console.log('array vacio');
          }
          
        
        console.log("listenIdProcessType",idValue)
    

        if (this.authService.getActiveMetadataProcess() === 1) {
          const processFound = this.processFieldList.find((process: Proceso) => {
            return +process.id === +idValue;
          });

          if (!processFound) {
            this.getDataFields();
          }

          if (processFound) {
            const fields = processFound.attributes.fields;
            this.pasoUno.removeControl('metadata');
            this.pasoUno.addControl('metadata', this.getDataFields(fields));
          }
        }
      });
  }

  fields: IField[] = [];
  getDataFields(fields: IField[] = []): AbstractControl {
    this.fields = fields;

    const metadataGroup: any = {};

    fields.forEach((field: IField) => {
      metadataGroup[field.field_id] = field.field_mandatory
        ? [null, [Validators.required,Validators.pattern('^([a-zA-Z0-9][^*/><?\"|:]*)$')]]
        : [null];
    });

    return this.fb.group(metadataGroup);
  }
  
}
